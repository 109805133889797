import { TYPES, TYPES_ENUM } from './TYPES';
import { deleteItemFactory } from './deletItemFactory';

export const deleteItem = (type: TYPES_ENUM) => {
	switch (type) {
		case TYPES.ACCOUNTING_RECORD: return deleteItemFactory(type);
		case TYPES.ACCOUNT: return deleteItemFactory(type);
		case TYPES.ASSET: return deleteItemFactory(type);
		case TYPES.AUTHOR: return deleteItemFactory(type);
		case TYPES.BOOK: return deleteItemFactory(type);
		case TYPES.BOOK_READING: return deleteItemFactory(type);
		case TYPES.DAY: return deleteItemFactory(type);
		case TYPES.EXTERNAL_ENTITY: return deleteItemFactory(type);
		case TYPES.LOG_ENTRY: return deleteItemFactory(type);
		case TYPES.LABEL: return deleteItemFactory(type);
		case TYPES.MOVIE: return deleteItemFactory(type);
		case TYPES.MOVIESCREENING: return deleteItemFactory(type);
		case TYPES.NOTE: return deleteItemFactory(type);
		case TYPES.DAY_STAT: return deleteItemFactory(type);
		case TYPES.PROJECT: return deleteItemFactory(type);
		case TYPES.RECIPE: return deleteItemFactory(type);
		case TYPES.OBJECTIVE: return deleteItemFactory(type);
		case TYPES.OBJECTIVE_PLAN: return deleteItemFactory(type);
		case TYPES.OWNED_BOOK: return deleteItemFactory(type);
		case TYPES.SKILL: return deleteItemFactory(type);
		case TYPES.STORY: return deleteItemFactory(type);
		case TYPES.TRANSACTION: return deleteItemFactory(type);
		case TYPES.TAG: return deleteItemFactory(type);
		case TYPES.TRANSACTION_BUNDLE: return deleteItemFactory(type);
		case TYPES.INGREDIENT: return deleteItemFactory(type);
		case TYPES.TIMELINE_DOT: return deleteItemFactory(type);
		case TYPES.WISHED_BOOK: return deleteItemFactory(type);
		default: {
			throw new Error('type not implemented');
		}
	};
};