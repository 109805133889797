import { SuportedObject } from "../../../utils/filterListByFilters";
import { AssetType, AssetTypeLabels } from "./assetType";
import { defaultUser, UserDictionary } from "../../../models";
import { getTransaction, TransactionDictionary } from "./transaction";
import { ASSET_STATE } from "./ASSET_STATE";
import { Account, AccountDictionary, defaultAccount, getAccount } from "./account";
import { DEFAULT_NEW_QUERY_ID } from "../../../constants";
import { buildGetModel } from "../../../models/buildGetModel";
import { TYPES_ENUM } from "../../../API";
import { Rates } from "../hooks";
import { VALID_CURRENCY } from "./currency";
import { formatPrice } from "../../../utils";

export interface Asset extends SuportedObject {
	id: string,
	type: string,
	currency: string,
	title: string,
	description: string,
	value: number,
	createdDate: string,
	owner: string,
	state: ASSET_STATE,
	account: Account | null,
	accountId: Account['id'],

	typeName: string,
	currencyName: string,
	displayedCreatedDate: string,
	displayedModifiedDate: string,
	ownerEmail: string,
	RONValue: number,
};

export interface AssetDictionary {
	[key: Asset['id']]: Asset
};

export const defaultAsset: Asset = {
	id: '',
	type: AssetType.CASH,
	typeName: '',
	currency: VALID_CURRENCY.RON,
	currencyName: '',
	title: '',
	description: '',
	value: 0,
	createdDate: '',
	displayedCreatedDate: '',
	displayedModifiedDate: '',
	owner: '',
	ownerEmail: '',
	RONValue: 0,
	state: ASSET_STATE.ACTIVE,
	accountId: '',
	account: { ...defaultAccount },
};

export const getAsset = buildGetModel<Asset, AssetDictionary>(TYPES_ENUM.ASSET, defaultAsset);

export const mapAssetForApi = (_queryId: string, asset: Asset): any => {
	let payload: { [key: string]: any } = {
		type: asset.type,
		currency: asset.currency,
		title: asset.title,
		description: asset.description,
		value: asset.value,
		transactionIn: asset.transactionIn.id || null,
		transactionOut: asset.transactionOut.id || null,
		state: asset.state,
	};
	if (_queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = asset.id;
		payload.accountId = asset.accountId;
	}
	return payload;
};

export const mapAssetFromAPI = (
	source: any,
	assetTypeLabels: AssetTypeLabels,
	userDictionary: UserDictionary,
	transactionDictionary: TransactionDictionary,
	accountDictionary: AccountDictionary,
	rates: Rates,
): Asset => {
	const foundAssetTypeLabel = assetTypeLabels[source.type as AssetType] || '';
	const foundUser = userDictionary[source.owner] || { ...defaultUser };
	const assetState = source.state || ASSET_STATE.ACTIVE as ASSET_STATE;
	const foundTransactionIn = getTransaction(source.transactionIn, transactionDictionary);
	const foundTransactionOut = getTransaction(source.transactionOut, transactionDictionary);
	const foundAccount = getAccount(source.accountId, accountDictionary);
	const usedRate = rates[source.currency as VALID_CURRENCY];
	return {
		id: source.id,
		type: source.type,
		typeName: foundAssetTypeLabel,
		currency: source.currency,
		currencyName: source.currency,
		title: source.title,
		description: source.description,
		value: source.value,
		createdDate: source.createdDate,
		displayedCreatedDate: source.createdDate,
		displayedModifiedDate: source.modifiedDate,
		owner: source.owner,
		ownerEmail: foundUser.email,
		// TODO: Fix this
		RONValue: formatPrice(source.value * usedRate),
		transactionIn: foundTransactionIn,
		state: assetState,
		transactionOut: foundTransactionOut,
		accountId: source.accountId,
		account: foundAccount,
	};
};

export const mapAssetsFromAPI = (
	assetsFromAPI: any[],
	assetTypeLabels: AssetTypeLabels,
	userDictionary: UserDictionary,
	transactionDictionary: TransactionDictionary,
	accountDictionary: AccountDictionary,
	rates: Rates,
): Asset[] => {
	return assetsFromAPI.map((source) => mapAssetFromAPI(source, assetTypeLabels, userDictionary, transactionDictionary, accountDictionary, rates));
};