import React, { useContext } from 'react';
import { Button, Icon, ICON_NAMES } from '..';
import { LabelsContext } from '../../context';

interface ExitButtonProps {
	onClick: () => void;
}

export const ExitButton: React.FunctionComponent<ExitButtonProps> = ({ onClick }) => {
	const { labels } = useContext(LabelsContext);
	return (
		<Button
			variant="contained"
			color="secondary"
			size="large"
			onClick={onClick}
		>
			<Icon iconName={ICON_NAMES.cancel} />
			{labels.exit}
		</Button>
	);
}

export default ExitButton;