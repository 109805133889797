import React from 'react';
import { Button, Icon, ICON_NAMES } from '..';

interface AddButtonProps {
	onClick: () => void;
	disabled?: boolean;
	labels: { add: string }
}

export const AddButton: React.FunctionComponent<AddButtonProps> = ({ onClick, disabled, labels }) => {
	return (
		<Button
			variant="contained"
			color="secondary"
			onClick={onClick}
			disabled={disabled}
		>
			<Icon iconName={ICON_NAMES.add} />
			{labels.add}
		</Button>
	);
}

export default AddButton;