import './timeline.css';
import React from 'react';
import TimelineEntry from './TimelineEntry';
import { Story } from '../../models';

interface TimelineProps {
	entries: Story[];
	renderTitle: (entry: Story) => JSX.Element;
	renderIcon: (entry: Story) => JSX.Element;
	renderContent: (entry: Story) => JSX.Element;
}

export const Timeline: React.FunctionComponent<TimelineProps> = ({ entries = [], renderTitle, renderIcon, renderContent }) => {
	return (
		<div className="wrapper">
			{entries.map((entry, index) => {
				return (
					<TimelineEntry key={index}
						entry={entry}
						renderTitle={renderTitle}
						renderIcon={renderIcon}
						renderContent={renderContent}
						isFirst={index === 0}
						isLast={index === entries.length - 1}
					/>
				);
			})}
		</div>
	);
}
