import { getItemFactory, GetItemFactoryReturnType } from "./getItemFactory";
import { TYPES_ENUM } from "./TYPES";

export const getItem = <T>(type: TYPES_ENUM): GetItemFactoryReturnType<T> => {
	switch (type) {
		case TYPES_ENUM.ACCOUNTING_RECORD: return getItemFactory(type);
		case TYPES_ENUM.ACCOUNT: return getItemFactory(type);
		case TYPES_ENUM.ACTIVITY_SUGGESTION: return getItemFactory(type);
		case TYPES_ENUM.ASSET: return getItemFactory(type);
		case TYPES_ENUM.AUTHOR: return getItemFactory(type);
		case TYPES_ENUM.BOOK: return getItemFactory(type);
		case TYPES_ENUM.BOOK_READING: return getItemFactory(type);
		case TYPES_ENUM.CONTACT: return getItemFactory(type);
		case TYPES_ENUM.DAY: return getItemFactory(type);
		case TYPES_ENUM.EXTERNAL_ENTITY: return getItemFactory(type);
		case TYPES_ENUM.LOG_ENTRY: return getItemFactory(type);
		case TYPES_ENUM.LABEL: return getItemFactory(type);
		case TYPES_ENUM.PHOTO: return getItemFactory(type);
		case TYPES_ENUM.RECIPE: return getItemFactory(type);
		case TYPES_ENUM.OBJECTIVE: return getItemFactory(type);
		case TYPES_ENUM.OBJECTIVE_PLAN: return getItemFactory(type);
		case TYPES_ENUM.OWNED_BOOK: return getItemFactory(type);
		case TYPES_ENUM.MOVIE: return getItemFactory(type);
		case TYPES_ENUM.MOVIESCREENING: return getItemFactory(type);
		case TYPES_ENUM.NOTE: return getItemFactory(type);
		case TYPES_ENUM.DAY_STAT: return getItemFactory(type);
		case TYPES_ENUM.PROJECT: return getItemFactory(type);
		case TYPES_ENUM.SKILL: return getItemFactory(type);
		case TYPES_ENUM.STORY: return getItemFactory(type);
		case TYPES_ENUM.TEXT: return getItemFactory(type);
		case TYPES_ENUM.TRANSACTION: return getItemFactory(type);
		case TYPES_ENUM.TAG: return getItemFactory(type);
		case TYPES_ENUM.INGREDIENT: return getItemFactory(type);
		case TYPES_ENUM.TRANSACTION_BUNDLE: return getItemFactory(type);
		case TYPES_ENUM.TIMELINE_DOT: return getItemFactory(type);
		case TYPES_ENUM.WISHED_BOOK: return getItemFactory(type);
		default: {
			throw new Error('type not implemented');
		}
	};
};