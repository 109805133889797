import React from 'react';
import { Button, Icon, ICON_NAMES } from '..';

interface ReloadButtonProps {
	onClick: () => Promise<void>;
	labels: { reload: string };
	disabled?: boolean;
	text?: string;
}

export const ReloadButton: React.FunctionComponent<ReloadButtonProps> = ({
	onClick,
	disabled,
	labels,
	text
}) => {
	return (
		<Button
			variant="contained"
			color="primary"
			onClick={onClick}
			disabled={disabled}
		>
			<Icon iconName={ICON_NAMES.reload} />
			{text || labels.reload}
		</Button>
	);
}

export default ReloadButton;