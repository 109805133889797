import React from 'react';
import { TextField } from '@mui/material';
import { withTooltip } from '../../HOC';
import { ValidationCallback } from '../CustomTextfield';
import { IFormValidator } from '../../validators';

export interface CustomDateFieldProps {
	autoFocus?: boolean;
	className?: string;
	disabled?: boolean;
	error?: boolean;
	id: string;
	label?: string;
	name: string;
	onChange: (e: React.ChangeEvent) => void;
	required?: boolean;
	placeholder?: string;
	validationCallback?: ValidationCallback;
	value: string; // TODO: Change this to date
	tooltip?: string;
	formValidator?: IFormValidator;
}

export const CustomDateField: React.FunctionComponent<CustomDateFieldProps> = (props) => {
	return withTooltip(renderField(props), props.tooltip || '');
};

const renderField = (props: CustomDateFieldProps) => {
	const { autoFocus, className, disabled, id, label, name, onChange, required, placeholder, value } = props;
	const evaluatedError = evaluateError(props);
	return (
		<TextField
			autoFocus={autoFocus}
			className={className}
			disabled={disabled}
			error={evaluatedError}
			required={required}
			type="date"
			id={id}
			name={name}
			label={label}
			placeholder={placeholder}
			onChange={onChange}
			InputLabelProps={{ shrink: true }}
			value={value}
		/>
	);
};

const evaluateError = (props: CustomDateFieldProps): boolean => {
	const { formValidator, name, value, error, validationCallback } = props;
	if (formValidator) {
		return formValidator.fieldHasErrors(name, { [name]: value });
	}

	return (error !== undefined) ? error : (validationCallback ? validationCallback(name, value) : false);
}
