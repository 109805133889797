import React from 'react';
import { SimpleYesNoDialog } from '../SimpleYesNoDialog';

interface DeleteModalProps {
	showDeleteModal: boolean;
	handleClose: () => void;
	handleConfirmDelete: () => Promise<void>;
	labels: {
		payAttention: string;
		delete: string;
		back: string;
	}
	deleteAlertText: string;
}

export const DeleteModal: React.FunctionComponent<DeleteModalProps> = (props) => {
	const { showDeleteModal, handleClose, labels, deleteAlertText, handleConfirmDelete } = props;
	return (
		<SimpleYesNoDialog
			open={showDeleteModal}
			handleClose={handleClose}
			title={labels.payAttention}
			text={deleteAlertText}
			handleConfirm={handleConfirmDelete}
			confirmButton={labels.delete}
			cancelButton={labels.back}
		/>
	);
}

export default DeleteModal;