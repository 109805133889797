import './CenterIconCard.css';
import React, { FunctionComponent } from 'react';

interface CenterIconCardProps {
	imageURL: string;
	imageAlt: string;
	iconName: string;
	title: string;
	details: string;
	destination: string;
}

export const CenterIconCard: FunctionComponent<CenterIconCardProps> = ({ imageURL, imageAlt, iconName, title, details, destination }) => {
	const iconClassName = getIconClassName(iconName);
	return (
		<div className="col-md-4 wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
			<div className="about-col">
				<div className="img">
					<img src={imageURL} alt={imageAlt} className="img-fluid"/>
					<div className="icon"><i className={iconClassName}></i></div>
				</div>
				<h2 className="title"><a href={destination}>{title}</a></h2>
				<p>
					{details}
				</p>
			</div>
		</div>
	);
}

export default CenterIconCard;

const getIconClassName = (iconName: string) => {
	switch(iconName) {
		case 'speedometer': return 'ion-ios-speedometer';
		case 'list': return 'ion-ios-list';
		case 'eye': return 'ion-ios-eye';
		default: return ''
	};
};


