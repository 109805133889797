import './file-display.css';
import React, { lazy } from 'react';
import { Card, CardContent, Typography, CardHeader, CardMedia } from '..'
import { withSuspense } from '../../HOC';

const Grid = lazy(() => import('../../components/Grid/Grid'));

interface FileDisplayProps {
	photo: {
		fileName: string;
		location: string;
		url: string;
		description: string;
	}
}

export const FileDisplay: React.FunctionComponent<FileDisplayProps> = ({ photo }) => {
	return withSuspense(
		<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
			<Card>
				<CardHeader
					title={photo.fileName}
					subheader={photo.location}
				/>
				<CardMedia
					className="media"
					image={photo.url}
				/>
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						{photo.description}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
}

export default FileDisplay;