import { USER_ROLES } from 'personal-api-types';
import { LabelsGraph } from '../../hooks/useLabels';
import { User } from '../../models';
import { Roles, toTitleCase } from '../../utils';
import { useContext } from 'react';
import { LabelsContext, UserContext } from '../../context';
import { useHistory } from 'react-router';
export const PUBLIC_APP_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'myStory',
		url: '/stories',
		roles: { [Roles.admin]: true, [Roles.visitor]: false }
	},
	{
		labelName: 'projects',
		url: '/projects',
		roles: { [Roles.admin]: true, [Roles.visitor]: false },
	},
	{
		labelName: 'contact',
		url: '/contact',
		roles: { [Roles.admin]: true, [Roles.visitor]: false },
	},
	{
		labelName: 'skills',
		url: '/skills',
		roles: { [Roles.admin]: true, [Roles.visitor]: false },
	},
];

export const CASH_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'transactions',
		url: '/transactions',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true }
	},
	{
		labelName: 'assets',
		url: '/assets',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true },
	},
	{
		labelName: 'externalEntities',
		url: '/external-entities',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true },
	},
	{
		labelName: 'accounts',
		url: '/accounts',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true },
	},
	{
		labelName: 'transactionBundles',
		url: '/transactionBundles',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true },
	},
	{
		labelName: 'accountingRecords',
		url: '/accountingRecords',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true },
	},
];

export const MOVIE_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'movieScreenings',
		url: '/movieScreenings',
		roles: { [Roles.admin]: true, [Roles.visitor]: true }
	},
	{
		labelName: 'movies',
		url: '/movies',
		roles: { [Roles.admin]: true, [Roles.visitor]: true }
	},
];

export const RECIPE_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'recipes',
		url: '/recipes',
		roles: { [Roles.admin]: true, [Roles.visitor]: true }
	},
	{
		labelName: 'ingredients',
		url: '/ingredients',
		roles: { [Roles.admin]: true, [Roles.visitor]: true }
	},
];

export const ADMIN_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'labels',
		url: '/labels',
		roles: { [Roles.admin]: true }
	},
	{
		labelName: 'tags',
		url: '/tags',
		roles: { [Roles.admin]: true }
	},
	{
		labelName: 'myAccount',
		url: '/user',
		roles: { [Roles.superUser]: true, [Roles.admin]: true, [Roles.visitor]: true }
	},
];

export const PHOTO_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'files',
		url: '/photos',
		roles: { [Roles.admin]: true }
	},
];

export interface HeaderItemDefinition {
	labelName: keyof LabelsGraph,
	url: string,
	roles: RolesBinding
}
const { admin, visitor, superUser } = Roles;
interface RolesBinding {
	[admin]?: boolean;
	[superUser]?: boolean;
	[visitor]?: boolean;
}


/**
 * @deprecated
*/
export const buildHeaderItems = (labels: LabelsGraph, createOnClick: (url: string) => () => void, user: User) => {
	return (ITEM_DEFS: HeaderItemDefinition[]) => {
		return ITEM_DEFS.filter((definition) => {
			return definition.roles[user.role] || user.role === USER_ROLES.admin;
		})
			.map((definition) => {
				return {
					primary: toTitleCase(labels[definition.labelName]),
					secondary: '',
					onClick: createOnClick(definition.url)
				};
			});
	};
};

export const useHeaderItems = (definitions: HeaderItemDefinition[]) => {
	const { labels } = useContext(LabelsContext);
	const { user } = useContext(UserContext);
	const history = useHistory();
	const createOnClick = (url: string) => {
		return () => history.push(url);
	};
	return definitions.filter((definition) => {
		return definition.roles[user.role] || user.role === USER_ROLES.admin;
	})
		.map((definition) => {
			return {
				primary: toTitleCase(labels[definition.labelName]),
				secondary: '',
				onClick: createOnClick(definition.url)
			};
		});
};