import { IconButton as MaterialIconButton, IconButtonProps as IconButtonPropsBase } from '@mui/material';
import React from 'react';

export interface IconButtonProps {
	color?: IconButtonPropsBase['color'];
	disabled?: IconButtonPropsBase['disabled'];
	onClick?: IconButtonPropsBase['onClick'];
	style?: IconButtonPropsBase['style'];
	'aria-label'?: IconButtonPropsBase['aria-label'];
	className?: IconButtonPropsBase['className'];
};
const IconButton = MaterialIconButton as React.FunctionComponent<IconButtonProps>;
export { IconButton };
export default IconButton;