
import React from 'react';
import { TableCell, Checkbox } from '@mui/material';

interface RowSelectionCellProps {
	isItemSelected: boolean
	disabled: boolean
	onSelect: (() => void);
}

export const RowSelectionCell: React.FC<RowSelectionCellProps> = (props) => {
	const { isItemSelected, disabled, onSelect } = props;

	const onChange = (ev: React.ChangeEvent) => {
		ev.stopPropagation();
		onSelect();
	}

	return (
		<TableCell padding="checkbox">
			<Checkbox
				checked={isItemSelected}
				disabled={disabled}
				onChange={onChange}
			/>
		</TableCell>
	);
};