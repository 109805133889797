import { SuportedObject } from '../../../utils/filterListByFilters';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';
import { getIngredient, Ingredient, IngredientDictionary } from './ingredient';
import { defaultUser, User, UserDictionary } from '../../../models/user';
import { ObjectFieldValidator } from '../../../validators';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';

export interface Recipe extends SuportedObject {
	id: string;
	title: string;
	markdown: string;
	createdDate: string;
	modifiedDate: string;
	createdBy: string;
	createdByEmail: string;
	tags: Tag['id'][];
	displayedTags: string;
	ingredients: Ingredient['id'][];
	ingredientList: Ingredient[];
};

export interface RecipeDictionary {
	[key: Recipe['id']]: Recipe
};

export const defaultRecipe: Recipe = {
	id: '',
	createdBy: '',
	createdByEmail: '',
	createdDate: '',
	displayedTags: '',
	ingredients: [],
	ingredientList: [],
	markdown: '',
	modifiedDate: '',
	tags: [],
	title: '',
};

export const getRecipe = (source: any, recipeDictionary: RecipeDictionary): Recipe => {
		//@ts-ignore
		const found = recipeDictionary[source];
		if (!found) {
			console.warn(`No recipe found for "${source}"`, source, recipeDictionary);
		}
		return found || defaultRecipe;
}

export const mapRecipeForApi = (_queryId: string, ingredient: Recipe, currentUser: User): any => {
	let payload: { [key: string]: any } = {
		createdBy: currentUser.id,
		ingredients: ingredient.ingredients,
		markdown: ingredient.markdown,
		labels: ingredient.tags,
		title: ingredient.title,
	};
	if (_queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = ingredient.id;
		payload.createdDate = ingredient.createdDate;
	}
	return payload;
};

export const mapRecipeFromAPI = (
	source: any,
	userDictionary: UserDictionary,
	tagDictionary: TagDictionary,
	ingredientsDictionary: IngredientDictionary,
): Recipe => {
	const displayedTags = (source.labels || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };

	const ingredientList = (source.ingredients || []).map((ingredientId: string) => getIngredient(ingredientId, ingredientsDictionary));

	return {
		id: source.id,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		createdDate: source.createdDate,
		displayedTags: displayedTags,
		ingredientList: ingredientList,
		ingredients: source.ingredients,
		markdown: source.markdown,
		modifiedDate: source.modifiedDate,
		tags: source.labels,
		title: source.title,
	};
};

export const mapRecipesFromAPI = (
	sources: any[],
	userDictionary: UserDictionary,
	tagDictionary: TagDictionary,
	ingredientDictionary: IngredientDictionary,
): Recipe[] => {
	return sources.map((source) => mapRecipeFromAPI(source, userDictionary, tagDictionary, ingredientDictionary));
};

export const validateRecipeField: ObjectFieldValidator<Recipe> = (fieldName, value) => {
	if (fieldName === 'createdBy' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	return {
		isValid: true,
		messageId: '',
	}
}