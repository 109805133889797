import { cloneDeep } from 'lodash';
import { DEFAULT_NEW_QUERY_ID } from '../constants';
import { SuportedObject } from '../utils/filterListByFilters';
import { defaultLocale, LocaleDictionary, Locale, getLocale, LocalesType } from './locales';

/**
 * Returns default UI object
 */
export const getDefaultLabel = () => {
	return {
		id: '',
		locale: '',
		message: '',
		name: '',
	};
};

/**
 * turns an object from the API intro an object for the UI
 * @param {object} o the API object.
*/
export const formatFromAPILabel = (o: any) => {
	const result = cloneDeep(o);
	return result;
};

export interface Label extends SuportedObject {
	id: string;
	locale: LocalesType;
	localeObject: Locale;
	message: string;
	name: string;
};


export interface LabelDictionary {
	[key: Label['id']]: Label
};

export const defaultLabel: Label = {
	id: '',
	locale: { ...defaultLocale }.value,
	localeObject: { ...defaultLocale },
	message: '',
	name: '',
};

export const mapLabelForApi = (queryId: string, label: Label): any => {
	let payload: { [key: string]: any } = {
		locale: label.locale,
		message: label.message,
		name: label.name,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = label.id
	}
	return payload;
};

export const mapLabelFromAPI = (
	source: any,
	localeDictionary: LocaleDictionary
): Label => {
	const foundLocale = getLocale(source.locale, localeDictionary);
	return {
		id: source.id,
		locale: foundLocale.value,
		localeObject: foundLocale,
		message: source.message,
		name: source.name,
	} as Label;
};

export const mapLabelsFromAPI = (
	sources: any[],
	localeDictionary: LocaleDictionary
): Label[] => {
	return sources.map((source) => mapLabelFromAPI(source, localeDictionary));
};