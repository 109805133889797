import moment from 'moment';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { UserDictionary, defaultUser } from '../../../models/user';

export interface LogEntry extends SuportedObject {
	id: string;
	title: string;
	location: string;
	paras: string[];
	author: string;
	authorEmail: string;
	createdDate: string;
	modifiedDate: string;
	createdBy: string;
};

export interface LogEntryDictionary {
	[key: LogEntry["id"]]: LogEntry;
};

export const defaultLogEntry: LogEntry = {
	id: '',
	title: '',
	location: '',
	paras: [],
	author: '',
	authorEmail: '',
	createdDate: '',
	modifiedDate: '',
	createdBy: '',
	createdByEmail: '',
};

export const mapLogEntryForApi = (queryId: string, logEntry: LogEntry): any => {
	let payload: { [key: string]: any } = {
		title: logEntry.title,
		location: logEntry.location,
		paras: logEntry.paras,
		author: logEntry.author,
		createdDate: logEntry.createdDate,
		modifiedDate: logEntry.modifiedDate,
		createdBy: logEntry.createdBy,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = logEntry.id;
	}
	return payload;
};

export const mapLogEntryFromAPI = (
	source: any,
	userDictionary: UserDictionary
): LogEntry => {
	const foundUser = userDictionary[source.author] || { ...defaultUser };
	const foundCreatedBy = userDictionary[source.createdBy] || { ...defaultUser };
	return {
		id: source.id,
		title: source.title,
		location: source.location,
		paras: source.paras,
		author: source.author,
		authorEmail: foundUser.email,
		createdDate: source.createdDate,
		modifiedDate: source.modifiedDate,
		createdBy: source.createdBy,
		createdByEmail: foundCreatedBy.email,
	};
};

export const mapLogEntriesFromAPI = (
	sources: any[],
	userDictionary: UserDictionary
): LogEntry[] => {
	return sources.map((source) => mapLogEntryFromAPI(source, userDictionary));
};

export const orderLogEntries = (projects: LogEntry[], isAscending: boolean): LogEntry[] => {
	return projects.sort((a, b) => {
		const isAfter = moment(a.modifiedDate, 'YYYY-MM-DD').isAfter(moment(b.modifiedDate, 'YYYY-MM-DD'));
		if (isAscending) {
			return isAfter ? 1 : -1;
		} else {
			return isAfter ? -1 : 1;
		}
	});
};