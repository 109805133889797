import { useState } from 'react';
import { ColumnTypesEnum } from './ColumnTypes';
import moment, { MomentFormatSpecification } from 'moment';
import { Column, SupportedRowType } from './CustomTable.types';
import { getCellValue } from './custom-table-helpers';

export type SortDirection = 'ascending' | 'descending';

export type UseTableSortingHook = {
	sortColumn: Column;
	setSortColumn: (newSortColumn: Column) => void;
	isAscending: boolean;
	setIsAscending: (newIsAscending: boolean) => void;
	sortList: (list: SupportedRowType[]) => SupportedRowType[];
}

export type ComparingValues = number | string | boolean | Date | null;

export const useTableSorting = (
	initialSortColumn: Column,
	initialSortDirection: SortDirection,
): UseTableSortingHook => {
	const [sortColumn, setSortColumn] = useState(initialSortColumn);
	const [isAscending, setIsAscending] = useState(initialSortDirection === 'ascending');

	const sortList = (list: SupportedRowType[]) => {
		if (sortColumn && list.length) {
			if (sortColumn && sortColumn.renderType && ColumnTypesEnum[sortColumn.renderType]) {
				const sortedListNew = [...list];
				sortedListNew.sort((a, b) => {
					return compareValues(
						sortColumn.renderType,
						getCellValue(a, sortColumn) as ComparingValues,
						getCellValue(b, sortColumn) as ComparingValues, isAscending,
						sortColumn.customSorter,
					);
				});
				return sortedListNew;
			}
		}
		return list;
	};

	return {
		sortColumn,
		setSortColumn,
		isAscending,
		setIsAscending,
		sortList,
	};
};

const compareValues = (type: ColumnTypesEnum, a: ComparingValues, b: ComparingValues, isAscending: boolean, customSorter?: Column['customSorter']): number => {
	if (type === ColumnTypesEnum.number) return compareNumbers(a as number, b as number, isAscending);
	if (type === ColumnTypesEnum.string) return compareStrings(a as string, b as string, isAscending);
	if (type === ColumnTypesEnum.momentDayMonthYear) return compareMoments(a as string, b as string, isAscending, 'DD-MM-YYY');
	if (type === ColumnTypesEnum.date) return compareDates(a as Date | null, b as Date | null, isAscending);
	if (type === ColumnTypesEnum.boolean) return compareBooleans(a as boolean | null, b as boolean | null, isAscending);
	if (type === ColumnTypesEnum.custom) {
		if (customSorter) {
			return customSorter(a, b, isAscending);
		}
		return 0;
	}
	return 0;
};

const compareNumbers = (a: number, b: number, isAscending: boolean) => {
	if (a > b) return isAscending ? 1 : -1;
	if (a < b) return isAscending ? -1 : 1;
	return 0;
};

const compareStrings = (a: string, b: string, isAscending: boolean) => {
	if (a > b) return isAscending ? 1 : -1;
	if (b > a) return isAscending ? -1 : 1;
	return 0;
};

const compareMoments = (a: string, b: string, isAscending: boolean, format: MomentFormatSpecification) => {
	const momentA = moment(a, format, true);
	const momentB = moment(b, format, true);
	if (momentB.isBefore(momentA, 'day')) return isAscending ? 1 : -1;
	if (momentA.isBefore(momentB, 'day')) return isAscending ? -1 : 1;
	return 0;
};

const compareDates = (a: Date | null, b: Date | null, isAscending: boolean) => {
	if (a == null) return isAscending ? 1 : -1;
	if (b == null) return isAscending ? -1 : 1;
	return isAscending ? a?.getTime() - b?.getTime() : b?.getTime() - a?.getTime();
};

const compareBooleans = (a: boolean | null, b: boolean | null, isAscending: boolean): number => {
	if (a == null) return isAscending ? 1 : -1;
	if (b == null) return isAscending ? -1 : 1;
	if (isAscending) {
		if (a && !b) return 1;
		if (!a && b) return -1;
		return 0;
	} else {
		if (a && !b) return -1;
		if (!a && b) return 1;
		return 0;
	}
};