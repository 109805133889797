import { defaultUser, User, UserDictionary } from '../../../models';
import { SuportedObject } from "../../../utils/filterListByFilters";
import { CurrencyDictionary, defaultCurrency } from './currency';
import { buildGetModel } from '../../../models/buildGetModel';
import { TYPES_ENUM } from '../../../API';

export interface Account extends SuportedObject {
	id: string,
	title: string,
	currency: string,
	currencyName: string,
	value: number,
	createdBy: string,
	createdByEmail: string,
	owner?: User,
	location: string,
}

export interface AccountDictionary {
	[key: Account['id']]: Account
};

export const defaultAccount: Account = {
	id: '',
	title: '',
	currency: '',
	currencyName: '',
	value: 0,
	createdBy: '',
	createdByEmail: '',
	location: ''
};

export const getAccount = buildGetModel<Account, AccountDictionary>(TYPES_ENUM.ACCOUNT, defaultAccount);

export const mapAccountFromAPI = (
	source: any,
	currenciesDictionary: CurrencyDictionary,
	userDictionary: UserDictionary
): Account => {
	const foundCurrency = currenciesDictionary[source.currency] || { ...defaultCurrency };
	const foundUser = userDictionary[source.owner] || { ...defaultUser };
	return {
		id: source.id,
		title: source.title,
		currency: source.currency,
		currencyName: foundCurrency.label,
		value: source.value,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		location: source.location,
	} as Account;
};

export const mapAccountsFromAPI = (
	accountsFromAPI: any[],
	currenciesDictionary: CurrencyDictionary,
	userDictionary: UserDictionary
): Account[] => {
	return accountsFromAPI.map((source) => mapAccountFromAPI(source, currenciesDictionary, userDictionary));
};