import { defaultMovie, getMovie, Movie, MovieDictionary, MovieTypes } from './movie';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';
import { defaultUser, User, UserDictionary } from '../../../models/user';
import moment from 'moment';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';

export const SCREENING_DATE_MOMENT_FORMAT = 'YYYY-MM-DD';

export enum PlatformType {
	netflix = 1,
	filelist = 2,
	cinema = 3,
}

export interface Platform {
	value: PlatformType;
	label: string;
}

type PlatformDictionary = {
	[key in PlatformType]: Platform;
}

const defaultPlatform: Platform = {
	value: PlatformType.netflix,
	label: '',
}

const getPlatform = (source: any, platformDictionary: PlatformDictionary): Platform => {
	//@ts-ignore
	const found = platformDictionary[source];
	if (!found) {
		console.warn(`No platform found for "${source}"`, source, platformDictionary);
	}
	return found || defaultPlatform;
}

export interface MovieScreening extends SuportedObject {
	id: string;
	movieId: string;
	movie: Movie;
	movieType: MovieTypes;
	details: string;
	platform: PlatformType;
	platformName: string;
	score: number;
	startedScreening: Date | null;
	finishedScreening: Date | null;
	movieMonthDate: string;
	createdBy: User['id'];
	createdByEmail: string;
	tags: Tag['id'][];
	displayedTags: string;
	languageName: string;
};

export interface MovieScreeningDictionary {
	[key: MovieScreening['id']]: MovieScreening
};

export const defaultMovieScreening: MovieScreening = {
	id: '',
	movieId: '',
	movie: { ...defaultMovie },
	movieType: defaultMovie.type,
	details: '',
	platform: PlatformType.netflix,
	platformName: '',
	score: 0,
	startedScreening: null,
	finishedScreening: null,
	movieMonthDate: '',
	createdBy: '',
	createdByEmail: '',
	tags: [],
	displayedTags: '',
	languageName: '',
};

export const mapMovieScreeningForApi = (queryId: string, movieScreening: MovieScreening): any => {
	let payload: { [key: string]: any } = {
		movieId: movieScreening.movieId,
		details: movieScreening.details,
		platform: movieScreening.platform,
		score: movieScreening.score,
		startedScreening: movieScreening.startedScreening,
		finishedScreening: movieScreening.finishedScreening,
		createdBy: movieScreening.createdBy,
		tags: movieScreening.tags,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = movieScreening.id;
	}
	return payload;
};

export const mapMovieScreeningFromAPI = (
	source: any,
	tagDictionary: TagDictionary,
	userDictionary: UserDictionary,
	movieDictionary: MovieDictionary,
	platformDictionary: PlatformDictionary,
): MovieScreening => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };

	const displayedTags = (source.tags || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	const foundMovie = getMovie(source.movieId, movieDictionary);

	const foundPlatform = getPlatform(source.platform, platformDictionary);

	return {
		id: source.id,
		movieId: source.movieId,
		movie: foundMovie,
		movieType: foundMovie.type,
		details: source.details,
		platform: source.platform,
		platformName: foundPlatform.label,
		score: source.score,
		startedScreening: source.startedScreening ? new Date(source.startedScreening) : null,
		finishedScreening: source.finishedScreening ? new Date(source.finishedScreening) : null,

		movieMonthDate: source.finishedScreening ? moment(source.finishedScreening).format('MM-YYYY') : (source.startedScreening ? moment(source.startedScreening).format('MM-YYYY') : ''),
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		tags: source.tags || [],
		displayedTags: displayedTags,
		languageName: foundMovie.language,
	}
};

export const mapMovieScreeningsFromAPI = (
	sources: any[],
	tagDictionary: TagDictionary,
	userDictionary: UserDictionary,
	movieDictionary: MovieDictionary,
	platformDictionary: PlatformDictionary,
): MovieScreening[] => {
	return sources.map((source) => mapMovieScreeningFromAPI(source, tagDictionary, userDictionary, movieDictionary, platformDictionary));
};