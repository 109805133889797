import './menu-link.css';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Typography } from '../Typography';

export interface MenuLinkProps {
	link: {
		url: string;
		text: string
	}
}

export const MenuLink: React.FunctionComponent<MenuLinkProps> = ({ link }) => {
	const history = useHistory();
	const [isSelected, setIsSelected] = React.useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsSelected(history.location.pathname === link.url);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const handleNavigate = (link: string) => (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		history.push(link);
	};

	return (
		<Typography className={isSelected ? 'selected' : ''} align="center" variant="h6" noWrap onClick={handleNavigate(link.url)}>
			{link.text}
		</Typography>
	);
}

export default MenuLink;