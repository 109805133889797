import { TYPES, TYPES_ENUM } from './TYPES';
import { getAllFactory } from './getAllFactory';

export const getItems = <T>(type: TYPES_ENUM) => {
	switch (type) {
		case TYPES.ACCOUNTING_RECORD: return getAllFactory<T>(type);
		case TYPES.ACCOUNT: return getAllFactory<T>(type);
		case TYPES.ASSET: return getAllFactory<T>(type);
		case TYPES.AUTHOR: return getAllFactory<T>(type);
		case TYPES.ARTICLE: return getAllFactory<T>(type);
		case TYPES.BOOK: return getAllFactory<T>(type);
		case TYPES.BOOK_READING: return getAllFactory<T>(type);
		case TYPES.DAY: return getAllFactory<T>(type);
		case TYPES.EXCHANGE_RATE: return getAllFactory<T>(type);
		case TYPES.EXTERNAL_ENTITY: return getAllFactory<T>(type);
		case TYPES.INGREDIENT: return getAllFactory<T>(type);
		case TYPES.LOG_ENTRY: return getAllFactory<T>(type);
		case TYPES.LABEL: return getAllFactory<T>(type);
		case TYPES.PHOTO: return getAllFactory<T>(type);
		case TYPES.MOVIE: return getAllFactory<T>(type);
		case TYPES.MOVIESCREENING: return getAllFactory<T>(type);
		case TYPES.NOTE: return getAllFactory<T>(type);
		case TYPES.DAY_STAT: return getAllFactory<T>(type);
		case TYPES.PROJECT: return getAllFactory<T>(type);
		case TYPES.RECIPE: return getAllFactory<T>(type);
		case TYPES.OBJECTIVE: return getAllFactory<T>(type);
		case TYPES.OBJECTIVE_PLAN: return getAllFactory<T>(type);
		case TYPES.OWNED_BOOK: return getAllFactory<T>(type);
		case TYPES.SKILL: return getAllFactory<T>(type);
		case TYPES.STORY: return getAllFactory<T>(type);
		case TYPES.TRANSACTION: return getAllFactory<T>(type);
		case TYPES.TOKEN: return getAllFactory<T>(type);
		case TYPES.USER: return getAllFactory<T>(type);
		case TYPES.LOCALE: return getAllFactory<T>(type);
		case TYPES.TAG: return getAllFactory<T>(type);
		case TYPES.TIMELINE_DOT: return getAllFactory<T>(type);
		case TYPES.TRANSACTION_BUNDLE: return getAllFactory<T>(type);
		case TYPES_ENUM.WISHED_BOOK: return getAllFactory<T>(type);
		default: {
			throw new Error('type not implemented');
		}
	};
};