import { SupportedObject } from "../../components";

type TextField<T> = keyof T & string;

export const mapListToSelectorOptions = <T extends SupportedObject>(
	list: T[],
	keyField: keyof T,
	textField: TextField<T>,
) => {
	if (!keyField) keyField = 'id';
	if (!textField) textField = 'title';
	return [...list.map((object) => ({ value: object[keyField], label: object[textField] }))];
};