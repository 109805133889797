import './CustomSelector.css';
import React, { ChangeEvent } from 'react';
import { Select, InputLabel, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ValidationCallback } from '../CustomTextfield';
import { getSelectorChangeParams } from '../../utils';
import { IconButton } from '../IconButton';
import { FormControl, MenuItem } from '..';

export interface Option {
	label: string,
	value: string | number
}

export interface CustomSelectorProps {
	className?: string;
	placeholder?: string;
	required?: boolean;
	validationCallback?: ValidationCallback;
	name: string;
	value: string | string[] | number | number[];
	error?: boolean;
	tooltip?: string;
	label?: string;
	multiple?: boolean;
	handleChange: (field: string, value: any) => void; // TODO: Change to onChange
	options: Option[];
	id: string;
	disabled?: boolean;
	optionRenderer?: (options: Option) => JSX.Element | string;
}

export const CustomSelector: React.FunctionComponent<CustomSelectorProps> = (props) => {
	const { placeholder, required, tooltip, label, error, validationCallback, name, value, multiple, handleChange, id, disabled, options, optionRenderer } = props;

	const _handleChange = (e: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
		let [field, value] = getSelectorChangeParams<string, string>(e);
		if (handleChange) {
			handleChange(field, value);
		}
	};

	const evaluatedError = (error !== undefined) ? error : (validationCallback ? validationCallback(name, value) : false);

	let tooltipEl;
	if (tooltip) {
		tooltipEl = (
			<Tooltip title={tooltip}>
				<IconButton aria-label="title">
					<InfoIcon />
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<FormControl className="form-control-selector" error={error}>
			<InputLabel htmlFor={name}>{label}</InputLabel>
			<Select
				multiple={multiple}
				value={value}
				//@ts-ignore
				onChange={_handleChange}
				inputProps={{
					required: required,
					name: name,
					id: id,
				}}
				disabled={disabled}
				error={evaluatedError}
				placeholder={placeholder}
				required={required}
			>
				{
					options.map(o => {
						return optionRenderer ? optionRenderer(o) : (
							<MenuItem key={o.value} value={o.value}>
								{o.label}
							</MenuItem>
						)
					})
				}
				{/* {
					optionsRendererUsed(options || [])
				} */}
			</Select>
			{tooltipEl}
		</FormControl>
	);
}

// const defaultOptionsRenderer = (options: Option[]) => {
// 	return options.map(o => {
// 		return <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
// 	});
// }

export default CustomSelector;