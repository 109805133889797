import './Overview.css';
import React from 'react';
import { Typography } from '../Typography';

interface OverviewPprops {
	title?: string;
	subtitle?: string;
	data: string[]
}

export const Overview: React.FC<OverviewPprops> = ({ title, subtitle, data }) => {
	return (
		<div className='overview'>
			<Typography variant="h3">
				{title || ''}
			</Typography>
			<Typography color="textSecondary">
				{subtitle || ''}
			</Typography>
			{
				data.map((d, i) => {
					return (
						<Typography key={`overview-line-${i}`} color="primary" variant="h5">
							{d}
						</Typography>
					);
				})
			}
		</div>
	)
};

export default Overview;