import './expand-button.css';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '../Button';

interface ExpandButtonProps {
	disabled: boolean;
	handleClick: () => void;
	isIcon: boolean;
	isOpen: boolean;
}

export const ExpandButton: React.FunctionComponent<ExpandButtonProps> = ({ handleClick, isOpen, isIcon, disabled }) => {
	// TODO: add unit tests
	if (isIcon) {
		return (
			<ExpandMoreIcon
				className={`expand ${isOpen ? 'expand-open' : ''}`}
				onClick={handleClick}
			/>
		);
	}
	return (
		<Button className="button" disabled={disabled} variant="contained" color="primary" onClick={handleClick}>
			<ExpandMoreIcon
				className={`expand ${isOpen ? 'expand-open' : ''}`}
			/>
		</Button>
	);
};

export default ExpandButton;