import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { withTooltip } from '../../HOC';
import { CheckboxProps } from '@mantine/core';

export enum CustomCheckboxColors {
	primary = 'primary',
	secondary = 'secondary',
	default = 'default',
}

export interface CustomCheckboxProps {
	id: string;
	checked: boolean | null;
	required?: boolean;
	placeholder?: string;
	onChange: CheckboxProps['onChange'];
	value: null | boolean;
	color?: CustomCheckboxColors;
	name: string;
	disabled?: boolean;
	tooltip?: string;
	label?: string;
	className?: string;
}

export const CustomCheckbox: React.FunctionComponent<CustomCheckboxProps> = (props: CustomCheckboxProps) => {
	return withTooltip(withLabel(renderCheckBox(props), props), props.tooltip || '');
};

const withLabel = (checkbox: JSX.Element, props: CustomCheckboxProps) => {
	if (props.label) {
		return (
			<FormControlLabel
				control={checkbox}
				label={props.label}
			/>
		);
	}
	return checkbox;
};

const renderCheckBox = (props: CustomCheckboxProps) => {
	const checked = props.checked !== undefined && props.checked !== null ? props.checked : undefined;
	return (
		<Checkbox
			id={props.id}
			onChange={props.onChange}
			checked={checked}
			value={props.value === null ? "indeterminate" : props.value}
			indeterminate={props.value === null}
			color={props.color}
			name={props.name}
			disabled={props.disabled}
		/>
	);
};
