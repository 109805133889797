import React, { useContext, useMemo } from 'react';
import { CustomSelector } from '..';
import { AppContext, LabelsContext } from '../../context';
import { App } from '../../AppEnum';
import { getAppName } from './getAppName';

export const AppSelector: React.FunctionComponent<{}> = () => {
	const { app, selectApp } = useContext(AppContext);
	const { labels } = useContext(LabelsContext);

	const appOptions = useMemo(() => {
		return [
			{
				value: App.admin,
				label: getAppName(App.admin, labels),
			},
			{
				value: App.books,
				label: getAppName(App.books, labels),
			},
			{
				value: App.finance,
				label: getAppName(App.finance, labels),
			},
			{
				value: App.movies,
				label: getAppName(App.movies, labels),
			},
			{
				value: App.progress,
				label: getAppName(App.progress, labels),
			},
			{
				value: App.recipes,
				label: getAppName(App.recipes, labels),
			},
			{
				value: App.health,
				label: getAppName(App.health, labels),
			},
			{
				value: App.minimalist,
				label: getAppName(App.minimalist, labels),
			},
		];
	}, [labels]);

	const handleSelectApp = (_field: string, value: string) => {
		selectApp(value as App);
	};

	return (
		<CustomSelector
			id="AppSelector"
			name="app"
			value={app}
			options={appOptions}
			handleChange={handleSelectApp}
		/>
	);
};

export default AppSelector;