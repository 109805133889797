import './FormPage.css';
import React, { lazy } from 'react';
import { Paper } from '../Paper';
import { PageTitle, SpinnerText, KeyboardSniffer, SUPPORTED_EVENTS, SaveButton } from '..';
import { withGrowAnimation, withFormInputWrapper, withSuspense } from '../../HOC';
import { ExitButton } from '../ExitButton';
import { DEFAULT_ELEVATION } from '../../constants';

const Grid = lazy(() => import('../../components/Grid/Grid'));

interface FormPageProps {
	handleSave: () => void;
	isLoading: boolean;
	getPageTitle: () => string;
	/**
	 * @deprecated use children.
	*/
	renderFields?: () => JSX.Element | null;
	TabPanel?: JSX.Element;
	renderBottomComponent?: (() => JSX.Element) | JSX.Element;
	actions: {
		save?: () => Promise<void>;
		explicitDisableSave?: boolean;
		exit?: () => void;
	};
	className?: string;
}

export const FormPage: React.FunctionComponent<FormPageProps> = (props) => {
	const { handleSave, isLoading, TabPanel, renderBottomComponent, children, className } = props;

	return withSuspense(withGrowAnimation(
		<div className="form-page-wrapper">
			{handleSave && <KeyboardSniffer handlers={{ [SUPPORTED_EVENTS.save]: handleSave }} />}
			<Paper style={{ padding: '8px' }} className={className} elevation={DEFAULT_ELEVATION}>
				<div className='form-page-container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%' }}>
						{renderTitle(props)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%' }}>
						{!isLoading && renderSpinner(props)}
					</Grid>
					<Grid item xs={12} sm={10} md={9} lg={6} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
						{!isLoading && renderFields(props)}
						{children}
					</Grid>
					{TabPanel && withFormInputWrapper(TabPanel)}
					<div style={{ alignSelf: 'stretch' }}>
						{typeof renderBottomComponent == 'function' ? renderBottomComponent() : renderBottomComponent}
					</div>
					{!isLoading && renderButtons(props)}
				</div>
			</Paper>
		</div>
	));
}

export default FormPage;

const renderTitle = ({ getPageTitle }: FormPageProps) => {
	return <PageTitle text={getPageTitle()} />;
};

const renderSpinner = ({ isLoading }: FormPageProps) => {
	if (isLoading) return <SpinnerText />;
};

const renderFields = ({ renderFields }: FormPageProps) => {
	if (renderFields) return renderFields();
};

const renderButtons = ({ actions, isLoading }: FormPageProps) => {
	const isSaveDisabled = actions.explicitDisableSave ? actions.explicitDisableSave : isLoading;
	return (
		<div className="form-page-button-row">
			{actions.save && (
				<SaveButton onClick={actions.save} disabled={isSaveDisabled} />
			)}
			{actions.exit && (
				<ExitButton onClick={actions.exit} />
			)}
		</div>
	);
};