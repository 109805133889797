import { SuportedObject } from '../utils/filterListByFilters';
import { LocalesType } from './locales';
import { User } from './user';

export interface Contact extends SuportedObject {
	id: string;
	address: string;
	email: string;
	facebook: string;
	github: string;
	gitlab: string;
	linkedIn: string;
	locale: string;
	modifiedDate: string; //"2022-02-12T16:31:24+02:00"
	name: string;
	phoneNumber: string;
};

export const defaultContact: Contact = {
	id: '',
	address: '',
	email: '',
	facebook: '',
	github: '',
	gitlab: '',
	linkedIn: '',
	locale: '',
	modifiedDate: '',
	name: '',
	phoneNumber: '',
};

export const mapContactForApi = (contact: Contact): any => {
	let payload: { [key: string]: any } = {
		id: contact.id,
		address: contact.address,
		email: contact.email,
		facebook: contact.facebook,
		github: contact.github,
		gitlab: contact.gitlab,
		linkedIn: contact.linkedIn,
		locale: contact.locale,
		modifiedDate: contact.modifiedDate,
		name: contact.name,
		phoneNumber: contact.phoneNumber,
	};
	return payload;
};

export const mapContactFromAPI = (
	source: any,
	user: User,
): Contact => {
	return {
		id: source.id,
		address: user.email ? source.address : '',
		email: source.email,
		facebook: source.facebook,
		github: source.github,
		gitlab: source.gitlab,
		linkedIn: source.linkedIn,
		locale: source.locale,
		modifiedDate: source.modifiedDate,
		name: source.name,
		phoneNumber: source.phoneNumber,
	};
};

export const getContactId = (locale: LocalesType): string => {
	switch (locale) {
		case LocalesType.en: return 'c09b17d5-1994-45ca-a62f-87c11196bdb0';
		case LocalesType.ro: return 'c7481dc8-2952-4195-a76c-fe232ee185aa';
		case LocalesType.fr: return 'ebb9b2ee-16d7-4aa4-907e-a201d369bf1a';
		default: return '';
	}
};