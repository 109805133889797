import React, { Suspense, lazy, useMemo } from 'react';
import { useContext } from 'react';
import { UsersContext } from '../../context';
import { User } from '../../models';
import { FilterChipsProps } from '../FilterChips';
import { TagLike, UseChipsResult } from '../FilterChips/useFilterChipsState';
import { Preloader } from '../Preloader';

const FilterChips = lazy(() => import('../FilterChips/FilterChips'));

export interface UserSelectorChipsProps {
	chipsHook: UseChipsResult<TagLike>;
	handleUserChipsChange: FilterChipsProps<TagLike>['onChipsClickHadler'];
}

export const UserSelectorChips: React.FunctionComponent<UserSelectorChipsProps> = (props) => {
	const { chipsHook, handleUserChipsChange } = props;
	const { users } = useContext(UsersContext);

	const userChips = useMemo(() => {
		return turnUsersIntoChips(users);
	}, [users]);

	return (
		<Suspense fallback={<Preloader isDbz />}>
			<FilterChips
				availableChips={userChips}
				useChipsHook={chipsHook}
				onChipsClickHadler={handleUserChipsChange}
			/>
		</Suspense>
	);
};

const turnUsersIntoChips = (users: User[]): TagLike[] => {
	return users.map(user => ({ id: user.id + '', text: user.email }));
};