import React, { useContext } from 'react';
import { Button } from '../Button';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '..';
import { LabelsContext } from '../../context';

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
	title: string;
}

export const CustomDialog: React.FunctionComponent<Props> = ({
	isOpen,
	handleClose,
	handleConfirm,
	title,
	children
}) => {
	const { labels } = useContext(LabelsContext);
	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle>
				{title}
			</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleConfirm} color="primary">
					{labels.save}
				</Button>
				<Button onClick={handleClose} color="secondary">
					{labels.cancelButton}
				</Button>
			</DialogActions>
		</Dialog>
	);
}