export enum LocalesType {
	en = "en",
	es = "es",
	fr = "fr",
	it = "it",
	ro = "ro",
}

export interface Locale {
	value: LocalesType;
	label: string;
}

export type LocaleDictionary = {
	[key in LocalesType]: Locale;
}

export const defaultLocale: Locale = {
	value: LocalesType.ro,
	label: '',
}

export const getLocale = (source: any, localeDictionary: LocaleDictionary): Locale => {
	//@ts-ignore
	const found = source ? localeDictionary[source.toLowerCase()] : '';
	if (!found) {
		console.warn(`No locale found for "${source}"`, source, localeDictionary);
	}
	return found || defaultLocale;
}