import { createContext } from 'react';
import { DEFAULT_LOCALE } from '../constants';

export type Locale = 'RO' | 'EN' | 'FR';

export interface LocaleContextProps {
	locale: Locale;
	selectLocale: (locale: Locale) => void;
}

export const LocaleContext = createContext<LocaleContextProps>({
	locale: DEFAULT_LOCALE,
	selectLocale: () => { },
});