import React from 'react';
import { CircularProgress } from '..';

interface SpinnerTextProps {
	className?: string;
}

export const SpinnerText: React.FunctionComponent<SpinnerTextProps> = ({ className }) => {
	const classNames = `margin--one-rem${className ? ' ' + className : ''}`;
	return <CircularProgress className={classNames} />;
}
