import moment from 'moment';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';
import { defaultUser, UserDictionary } from '../../../models/user';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';
export const DATE_MOMENT_FORMAT = 'YYYY-MM-DD';

export interface TimelineDot extends SuportedObject {
	id: string;
	text: string;
	createdBy: string;
	createdByEmail: string;
	date: Date,
	tags: string[],
	mappedTags: Tag[],
	displayedTags: string,
};

export interface TimelineDotDictionary {
	[key: TimelineDot['id']]: TimelineDot
};

export const defaultTimelineDotProps = {
	id: '',
	text: '',
	createdBy: '',
	date: new Date(),
	displayedDate: moment().format(DATE_MOMENT_FORMAT),
	tags: [],
	displayedTags: '',
};

export const defaultTimelineDot: TimelineDot = {
	id: '',
	text: '',
	createdBy: '',
	createdByEmail: '',
	date: new Date(),
	tags: [],
	mappedTags: [],
	displayedTags: '',
};

export const mapTimelineDotForApi = (queryId: string, timelineDot: TimelineDot): any => {
	let payload: { [key: string]: any } = {
		text: timelineDot.text,
		createdBy: timelineDot.createdBy,
		date: moment(timelineDot.date, DATE_MOMENT_FORMAT).toDate(),
		tags: timelineDot.tags,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = timelineDot.id;
	}
	return payload;
};

export const mapTimelineDotFromAPI = (
	source: any,
	userDictionary:
		UserDictionary, tagDictionary: TagDictionary
): TimelineDot => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };
	let displayedTags = '';
	const mappedTags = source.tags.map((tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		displayedTags += foundTag.text + ' ';
		return foundTag as Tag;
	});
	displayedTags.trimEnd();

	return {
		id: source.id,
		text: source.text,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		date: moment(source.date).toDate(),
		tags: source.tags,
		mappedTags: mappedTags,
		displayedTags: displayedTags,
	} as TimelineDot;
};

export const mapTimelineDotsFromAPI = (
	sources: any[],
	userDictionary: UserDictionary,
	tagDictionary: TagDictionary
): TimelineDot[] => {
	return sources.map((source) => mapTimelineDotFromAPI(source, userDictionary, tagDictionary));
};