import axios from 'axios';
import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useHistory } from 'react-router';
import { getItems } from '../API';
import { getDefaultHeaders } from '../API/getDefaultHeaders';
import { getResourceURL } from '../API/getResourceURL';
import { getToken } from '../API/getToken';
import { TYPES_ENUM } from '../API/TYPES';
import { ErrorContext } from '../context';
import { defaultUser, User } from '../models';
import { LOCAL_STORAGE_KEYS } from '../utils';

type ResponseType = [
	User,
	boolean,
	boolean,
	() => Promise<void>,
	(userName: string, passwordHash: string) => Promise<void>,
	() => Promise<void>,
];

export const useUser = (): ResponseType => {
	// TODO: abort http, useCallback, useMemo(user), treat error
	const [user, setUser] = useState<User>(defaultUser);
	const [isLoadingUser, setIsLoadingUser] = useState(false);
	const history = useHistory();
	const { updateError } = useContext(ErrorContext);
	// const cancelToken = axios.CancelToken.source();

	const loadUser = useCallback(async () => {
		const token = getToken();
		setIsLoadingUser(true);
		if (token) {
			try {
				const result = await getItems(TYPES_ENUM.TOKEN)({}) as unknown as User;
				setUser(result);
			} catch (error) {
				updateError(error as Error);
			}
		}
		setIsLoadingUser(false);
	}, [updateError]);

	const login = useCallback(async (userName: string, passwordHash: string) => {
		setIsLoadingUser(true);
		try {
			const result = await axios.post(
				getResourceURL(TYPES_ENUM.LOGIN),
				{ userName: userName, password: passwordHash },
				{
					headers: getDefaultHeaders(),
				}
			)
			localStorage.setItem(LOCAL_STORAGE_KEYS.token, result.data.data.token);
			setUser(result.data.data.user);
			history.push('/');
		} catch (err) {
			updateError(err as Error);
		} finally {
			setIsLoadingUser(false);
		}
	}, [history, updateError]);

	const logout = useCallback(async () => {
		localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
		setUser(defaultUser);
		history.push('/');
	}, [history]);

	useEffect(() => {
		loadUser();
	}, [loadUser]);

	const isAdmin = useMemo(() => {
		return user.role === 'admin';
	}, [user]);

	return [user, isAdmin, isLoadingUser, loadUser, login, logout];
};

// const treatAxiosError = (error) => {
// 	if (axios.isCancel(error)) {
// 		// request was canceled no error
// 		return;
// 	}
// 	console.error(error);
// };