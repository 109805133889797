import { createContext } from 'react';
import { defaultLabelsGraph, LabelsGraph } from '../hooks/useLabels';

interface LabelsContextProps {
	labels: LabelsGraph;
	isLoadingLabels: boolean;
}

export const LabelsContext = createContext<LabelsContextProps>({
	labels: defaultLabelsGraph,
	isLoadingLabels: false
});