import React from "react";

type ResponseTuple<FieldType, ValueType> = [
	FieldType,
	ValueType,
];

/**
 * Returns the value of an input
*/
export const getInputChangeParams = <FieldType, ValueType>(e: React.ChangeEvent): ResponseTuple<FieldType, ValueType> => {
	//@ts-ignore
	const field = e.target.name as FieldType;
	//@ts-ignore
	const value = (e.target.type === 'checkbox' ? e.target.checked : e.target.value) as ValueType;
	return [field, value];
};