import { Option } from '../../../context';
import { LabelsGraph } from '../../../hooks';
export interface Currency {
	label: string,
	value: string | number,
};

export interface CurrencyDictionary {
	[key: Currency['value']]: Currency
};

export const defaultCurrency: Currency = {
	label: '',
	value: '',
};

export enum VALID_CURRENCY {
	RON = 'RON',
	EUR = 'EUR',
	USD = 'USD',
}

export const getCurrencySelectorOptions = (labels: LabelsGraph): Option[] => {
	return [
		{
			label: 'RON',
			value: VALID_CURRENCY.RON
		},
		{
			label: 'EUR',
			value: VALID_CURRENCY.EUR
		},
		{
			label: 'USD',
			value: VALID_CURRENCY.USD
		},
	]
}