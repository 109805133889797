import { createContext } from 'react';
import { Tag, TagDictionary } from '../models';

interface TagsContextProps {
	isLoadingTags: boolean;
	isDeletingTag: boolean;
	tags: Tag[];
	tagDictionary: TagDictionary;
	selectedTags: Tag[];
	loadTags: (params: any) => Promise<void>;
	selectTags: (tagIds: Tag[]) => void;
	selectAllTags: () => void;
	deleteTag: (id: Tag['id']) => Promise<void>;
	overwriteSelected: (newTags: Tag[]) => void;
}

export const TagsContext = createContext<TagsContextProps>({
	isLoadingTags: false,
	isDeletingTag: false,
	tags: [],
	tagDictionary: {},
	selectedTags: [],
	loadTags: async () => { },
	selectTags: () => { },
	selectAllTags: () => { },
	deleteTag: async () => { },
	overwriteSelected: () => { },
});