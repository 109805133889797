import './context-menu.css';
import React from 'react';
import { ICON_NAMES, Icon, Menu, MenuItem } from '..';

export interface MenuuItemProps {
	text: string;
	params: any;
	onClick: Function;
}

interface ContextMenuProps {
	locale: string;
	onClick: (e: React.MouseEvent) => void;
	anchorEl?: Element;
	id: string;
	open: boolean;
	onClose: () => void;
	items: MenuuItemProps[];
}

export const ContextMenu: React.FunctionComponent<ContextMenuProps> = (props) => {
	const { locale } = props;

	return (
		<>
			<Icon
				iconName={ICON_NAMES.Language}
				className="header-language-icon"
				onClick={props.onClick}
			/>
			<span className="header-language-icon">
				{locale}
			</span>
			<Menu
				anchorEl={props.anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={props.id}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={props.open}
				onClose={props.onClose}
			>
				{
					props.items.map(item => {
						return <MenuItem key={`menu-item-${item.text}`} onClick={item.onClick(item.params)}>{item.text}</MenuItem>
					})
				}
			</Menu>
		</>
	);
}

export default ContextMenu;