import { Chip as MaterialChip, ChipProps as ChipPropsBase } from '@mui/material';
import React from 'react';

export interface ChipProps {
	avatar?: ChipPropsBase['avatar'];
	className?: ChipPropsBase['className'];
	color?: ChipPropsBase['color'];
	label?: ChipPropsBase['label'];
	onClick?: ChipPropsBase['onClick'];
	style?: ChipPropsBase['style'];
};
const Chip = MaterialChip as React.FunctionComponent<ChipProps>;
export { Chip };
export default Chip;