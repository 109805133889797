import './AdminDrawer.css';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Icon, ICON_NAMES, Button, CollapsableList, Typography, Divider } from '..';
import { getPreferredColorScheme, THEMES } from '../../utils';
import { ADMIN_DEFINITIONS, CASH_DEFINITIONS, MOVIE_DEFINITIONS, PHOTO_DEFINITIONS, PUBLIC_APP_DEFINITIONS, RECIPE_DEFINITIONS, buildHeaderItems } from '../Header/itemDefinitions';
import { AppContext, LabelsContext, LocaleContext, UserContext, Locale } from '../../context';
import { Paper } from '../Paper';
import { App } from '../../AppEnum';
import { AppName } from './AppName';
import AppSelector from './AppSelector';
import { MenuList } from '../MenuList';
import { useTheme } from '../../hooks';

export const AdminDrawer: React.FunctionComponent<{}> = ({ children }) => {
	const theme = useTheme();
	const history = useHistory();
	const createOnClick = (url: string) => {
		return () => history.push(url);
	};
	const { user, logout } = React.useContext(UserContext);
	const { labels } = useContext(LabelsContext);
	const { locale, selectLocale } = useContext(LocaleContext);
	const { app, handleExit } = useContext(AppContext);
	const isDarkMode = getPreferredColorScheme() === THEMES.dark;
	// TODO: Use Valid locales here
	const createChangeLanguage = (_locale: Locale) => {
		return async () => {
			if (_locale === locale) return;
			selectLocale(_locale);
		};
	};
	const handleLogout = () => logout();
	const publicAppItems = buildHeaderItems(labels, createOnClick, user)(PUBLIC_APP_DEFINITIONS);
	const cashItems = buildHeaderItems(labels, createOnClick, user)(CASH_DEFINITIONS);
	const movieItems = buildHeaderItems(labels, createOnClick, user)(MOVIE_DEFINITIONS);
	const recipeItems = buildHeaderItems(labels, createOnClick, user)(RECIPE_DEFINITIONS);
	const adminItems = buildHeaderItems(labels, createOnClick, user)(ADMIN_DEFINITIONS);
	const photoItems = buildHeaderItems(labels, createOnClick, user)(PHOTO_DEFINITIONS);
	const languageItems = [
		{
			primary: labels.romanianLanguage,
			onClick: createChangeLanguage('RO'),
		},
		{
			primary: labels.englishLanguage,
			onClick: createChangeLanguage('EN'),
		},
		{
			primary: labels.frenchLanguage,
			onClick: createChangeLanguage('FR'),
		}
	];

	const sectionClassName = `section${isDarkMode ? ' section-dark' : ''}`;
	const sectionItemClassName = `section-item${isDarkMode ? ' section-item-dark' : ''}`;

	return (
		<Paper id="admin-drawer" className="admin-drawer" style={{ backgroundColor: theme.palette.background.paper }}>
			<Typography onClick={createOnClick('/')} className="title" align="center" color="textPrimary" display="block" variant="h5" >
				{labels.name}
			</Typography>
			<AppName app={app} />
			<AppSelector />
			<br />
			<br />
			<Divider />
			{children}
			{app === App.finance && (
				<>
					<CollapsableList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={cashItems}
						title={labels.finance}
						titleIcon={ICON_NAMES.Cash}
						isOpen
					/>
					<Divider />
				</>
			)}
			{app === App.admin && (
				<>
					<CollapsableList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={publicAppItems} title={labels.publicApp}
						titleIcon={ICON_NAMES.Work}
					/>
					{!!publicAppItems.length && <Divider />}
				</>
			)}
			{app === App.movies && (
				<>
					<MenuList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={movieItems}
					/>
					<Divider />
				</>
			)}
			{app === App.recipes && (
				<>
					<MenuList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={recipeItems}
					/>
					<Divider />
				</>
			)}
			{app === App.admin && (
				<>
					<CollapsableList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={photoItems}
						title={labels.files}
						titleIcon={ICON_NAMES.Photo}
					/>
					{!!photoItems.length && <Divider />}
				</>
			)}
			{app === App.admin && (
				<>
					<CollapsableList
						classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
						list={adminItems}
						title={labels.adminStuff}
						titleIcon={ICON_NAMES.Build}
					/>
					{!!adminItems.length && <Divider />}
				</>
			)}
			<CollapsableList
				classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
				list={languageItems}
				title={labels.currentLanguage + ' ' + locale}
				titleIcon={ICON_NAMES.Language}
			/>
			<Divider />
			<div className="logout-button-wrapper">
				<Button
					onClick={handleLogout}
					color="secondary"
					variant="outlined"
					startIcon={<Icon iconName={ICON_NAMES.Power}
						style={{}} />}
				>
					{labels.logout}
				</Button>
			</div>
			{app !== App.none && (
				<div className="logout-button-wrapper">
					<Button
						onClick={handleExit}
						color="secondary"
						variant="outlined"
						startIcon={<Icon iconName={ICON_NAMES.cancel}
							style={{}} />}
					>
						{labels.exit}
					</Button>
				</div>
			)}
		</Paper>
	);
};