import { TYPES_ENUM } from "../API/TYPES";
import { buildGetModel } from "./buildGetModel";
import { USER_ROLES } from 'personal-api-types';

export interface User {
	id: string,
	email: string,
	role: USER_ROLES,
	status: string
}

export interface UserDictionary {
	[key: User['id']]: User
};

export const defaultUser: User = {
	id: '',
	email: '',
	role: USER_ROLES.visitor,
	status: '',
};

export const getUser = buildGetModel<User, UserDictionary>(TYPES_ENUM.USER, defaultUser);