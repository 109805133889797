import { createContext } from 'react';
import { User } from '../models/user';
import { USER_ROLES } from 'personal-api-types';


interface UserContextProps {
	isAdmin: boolean;
	isLoadingUser: boolean;
	user: User;
	login: (userName: string, passwordHash: string) => Promise<void>;
	logout: () => Promise<void>;
}

export const UserContext = createContext<UserContextProps>({
	isAdmin: false,
	isLoadingUser: false,
	user: { id: '', email: '', role: USER_ROLES.visitor, status: '' },
	login: async (userName, passwordHash) => { },
	logout: async () => { }
});