import React from "react";

type ResponseTuple<FieldType, ValueType> = [
	FieldType,
	ValueType,
];

export const getSelectorChangeParams = <FieldType, ValueType>(event: React.ChangeEvent<{ name?: string; value: unknown }>): ResponseTuple<FieldType, ValueType> => {
	const field = event.target.name as FieldType;
	const value = event.target.value as ValueType;
	return [field, value];
};