import { LabelsGraph } from "../../../hooks";
import { Option } from "../../../components";

export enum ExternalEntityCategory {
	BILLS = 'BILLS',
	TRANSPORT = 'TRANSPORT',
	PARTY = 'PARTY',
	THREADS = 'THREADS',
	MISC = 'MISC',
	FOOD = 'FOOD',
	HOME = 'HOME',
	SALARY = 'SALARY',
	PROJECT = 'PROJECT',
	CARRER = 'CARRER',
	HEALTH = 'HEALTH',
	GAMES = 'GAMES',
	TRAVEL = 'TRAVEL',
	BANKS = 'BANKS',
	INVESTMENTS = 'INVESTMENTS',
}

const ExternalEntityCategoryLabelKeys: { [key in ExternalEntityCategory]: keyof LabelsGraph } = {
	[ExternalEntityCategory.BILLS]: 'categoryBills',
	[ExternalEntityCategory.TRANSPORT]: 'categoryTransport',
	[ExternalEntityCategory.PARTY]: 'categoryParty',
	[ExternalEntityCategory.THREADS]: 'categoryClothes',
	[ExternalEntityCategory.MISC]: 'categoryMisc',
	[ExternalEntityCategory.FOOD]: 'categoryFood',
	[ExternalEntityCategory.HOME]: 'categoryHome',
	[ExternalEntityCategory.SALARY]: 'categoryEmployer',
	[ExternalEntityCategory.PROJECT]: 'categoryProject',
	[ExternalEntityCategory.CARRER]: 'categoryCareer',
	[ExternalEntityCategory.HEALTH]: 'categoryHealth',
	[ExternalEntityCategory.GAMES]: 'categoryGames',
	[ExternalEntityCategory.TRAVEL]: 'categoryTravel',
	[ExternalEntityCategory.BANKS]: 'categoryBanks',
	[ExternalEntityCategory.INVESTMENTS]: 'categoryInvestments',
}

export const getOptions = (labels: LabelsGraph): Option[] => {
	return Object.values(ExternalEntityCategory).map((value) => {
		return {
			label: labels[ExternalEntityCategoryLabelKeys[value]],
			value: value,
		};
	});
}

export const getCategoryLabel = (labels: LabelsGraph, value: ExternalEntityCategory): string => {
	return labels[ExternalEntityCategoryLabelKeys[value]];
};