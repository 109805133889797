import React from 'react';
import { Cell, Legend, Pie, PieChart, LegendProps } from 'recharts';
import { RANDOM_COLORS } from '../../utils';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx, cy, midAngle, innerRadius, outerRadius, percent
}: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

interface CustomPieChartProps {
	data: {
		name: string;
		value: number;
		color: string;
	}[];
	hasLegend?: boolean;
	legendLocation?: LegendProps["verticalAlign"];
	height: number;
	width: number;
}

export const CustomPieChart: React.FunctionComponent<CustomPieChartProps> = ({ data, hasLegend, legendLocation, height, width }) => {
	return (
		<PieChart width={height} height={width}>
			<Pie
				data={data}
				dataKey="value"
				nameKey="name"
				label={renderCustomizedLabel}
			>
				{
					data.map((entry, index) => <Cell key={`cell-${entry.name}`} fill={entry.color || RANDOM_COLORS[index % RANDOM_COLORS.length]} />)
				}
			</Pie>
			{hasLegend && <Legend verticalAlign={legendLocation || 'bottom'} align="left" />}
			<Legend verticalAlign="bottom" height={36} />
		</PieChart>
	);
};

export default CustomPieChart;