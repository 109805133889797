import './sort-arrow.css';
import React from 'react';
import { Icon, ICON_NAMES } from '..';

interface SortArrowComponentProps {
	columnName: string
	sortColumn: string
	isAscending: boolean
}

export const SortArrowComponent: React.FunctionComponent<SortArrowComponentProps> = ({ columnName, sortColumn, isAscending }) => {
	if (!sortColumn) return null;
	if (columnName !== sortColumn) return null;
	return <Icon className={`directional-arrow ${isAscending ? 'unrotate' : 'rotate'}`} iconName={ICON_NAMES.arrowUp} />;
};