import { Option } from "../../../components";
import { LabelsGraph } from "../../../hooks";

export enum ASSET_STATE {
	ACTIVE = 'ACTIVE',
	DISPOSED = 'DISPOSED',
};

export const labelKey: { [key in ASSET_STATE]: keyof LabelsGraph } = {
	ACTIVE: 'active',
	DISPOSED: 'disposedFromHeritage',
};

export const getOptions = (labelsGraph: LabelsGraph): Option[] => {
	return [
		{
			label: labelsGraph[labelKey[ASSET_STATE.ACTIVE]],
			value: ASSET_STATE.ACTIVE,
		},
		{
			label: labelsGraph[labelKey[ASSET_STATE.DISPOSED]],
			value: ASSET_STATE.DISPOSED,
		}
	]
};