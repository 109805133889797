import { ComparingValues } from './useTableSorting';
import { Column, SupportedRowType } from './CustomTable.types';
import { SupportedObject } from './CustomTable';
import { isDay } from '../../models';

/**
 * Extracts value from row using a column
*/
export const getCellValue = (row: SupportedRowType, column: Column): ComparingValues | SupportedObject => {
	if (column.customValueExtractor) return column.customValueExtractor(row);
	const fields = column.cellName.split('.');
	if (fields.length) return getObjectValue(row, fields) || (column.defaultValue !== undefined ? column.defaultValue : '');
	return row[column.cellName as keyof SupportedRowType] || (column.defaultValue !== undefined ? column.defaultValue : '');
};

const getObjectValue = (object: { [key: string]: any }, fields: string[]): string | number | boolean | SupportedObject => {
	if (!fields.length) return '';
	if (fields.length === 1) return object[fields[0]] || '';
	return getObjectValue(object[fields[0]] || {}, fields.slice(1))
};

/**
 * Asserts if row is selected
*/
export const getIsSelected = (row: SupportedRowType, selectedLines: SupportedRowType[]): boolean => {
	// TODO: Remove this disgusting hack after days are fixed
	const selectionKey = (isDay(row) ? 'key' : 'id') as keyof SupportedRowType;
	if (selectedLines.length === 0) return false;
	if (selectedLines.length) {
		return !!selectedLines.find(sl => sl[selectionKey] === row[selectionKey]);
	}
	return false;
};
