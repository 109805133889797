import axios from 'axios';
import { getDefaultHeaders } from './getDefaultHeaders';
import { getResourceURL } from './getResourceURL';
import { parseDefaultResult } from './parseDefaultResult';
import { TYPES_ENUM } from './TYPES';

type PostItemFactoryResponseType<T> = (object: T) => Promise<T>;

export const postItemFactory = <T>(type: TYPES_ENUM): PostItemFactoryResponseType<T> => {
	return (object: T) => {
		return new Promise((resolve, reject) => {
			if (!object) throw new Error('No object passed for posting!');
			axios.post(
				getResourceURL(type),
				object,
				{
					headers: getDefaultHeaders(),
				}
			)
				.then(response => resolve(parseDefaultResult(response) as T))
				.catch(err => reject(err))
		});
	};
};
