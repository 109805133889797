import React, { useContext } from 'react';
import { Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorContext } from '../../context';
import { IconButton } from '../IconButton';

interface ErrorModalProps { };

export const ErrorModal: React.FunctionComponent<ErrorModalProps> = () => {
	const { error, updateError } = useContext(ErrorContext);

	const handleClose = () => {
		updateError(new Error(''));
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={!!error}
			autoHideDuration={3000}
			onClose={handleClose}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={
				<span id="message-id">{error}</span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>,
			]}
		/>
	);
}

export default ErrorModal;