import React from 'react';
import { List, ListItem, ListItemText } from '../List';

interface ListItemProps {
	onClick?: () => void;
	primary: string;
	secondary?: string;
}

export interface MenuListProps {
	classNames?: {
		anchor: string;
		items: string;
	};
	isOpen?: boolean;
	list: ListItemProps[];
	title?: string;
}

export const MenuList: React.FunctionComponent<MenuListProps> = ({
	list,
	classNames
}) => {
	if (!list.length) return null;
	const itemClasses = classNames && classNames.items ? classNames.items : '';

	return (
		<List>
			{
				list.map((o, i) => {
					return (
						<ListItem key={i} onClick={o.onClick || undefined} className={itemClasses}>
							<ListItemText primary={o.primary} />
						</ListItem>
					);
				})
			}
		</List>
	);
}

export default MenuList;