import { TYPES_ENUM } from '../API';

export const buildGetModel = <Model, Dictionary>(resource: TYPES_ENUM, defaultValue: Model) => {
	return (source: string, dictionary: Dictionary) => {
		//@ts-ignore
		const found = dictionary[source];
		if (source && !found) {
			console.warn(getWarningText(resource, source), source, dictionary);
		}
		return found || defaultValue;
	}
}

const getWarningText = (resource: TYPES_ENUM, source: string) => {
	return `No ${ResourceLogTitle[resource]} found for "${source}"`;
}

const ResourceLogTitle: { [key in TYPES_ENUM]: string } = {
	ACCOUNTING_RECORD: 'accounting record',
	ACCOUNT: 'account',
	ASSET: 'asset',
	AUTHOR: 'author',
	BOOK: 'book',
	ASSETHISTORY: 'assetHistory',
	ARTICLE: 'article',
	CONTACT: 'contact',
	CURRENCY: 'currency',
	DAY: 'day',
	EXCHANGE_RATE: 'exchangeRate',
	EXTERNAL_ENTITY: 'external entity',
	INGREDIENT: 'ingredient',
	LABEL_GRAPH: 'labelGraph',
	LABEL: 'label',
	LOG_ENTRY: 'logEntry',
	LOGIN: 'login',
	MOVIE: 'movie',
	MOVIESCREENING: 'movieScreening',
	OBJECTIVE: 'objective',
	PHOTO: 'photo',
	PROJECT: 'project',
	SKILL: 'skill',
	STORY: 'story',
	TEXT: 'text',
	TOKEN: 'token',
	TRANSACTION: 'transaction',
	USER: 'user',
	NOTE: 'note',
	LOCALE: 'locale',
	MOVIE_GRAPH: 'movieGraph',
	TRANSACTION_GRAPH: 'transactionGraph',
	ACTIVITY_SUGGESTION: 'activitySuggestion',
	TAG: 'tag',
	RECIPE: 'recipe',
	TRANSACTION_BUNDLE: 'transactionBundle',
	DAY_STAT: 'dayStat',
	OBJECTIVE_STATS: 'objectiveStats',
	OBJECTIVE_PLAN: 'objectivePlans',
	TIMELINE_DOT: 'TimelineDot',
	OWNED_BOOK: 'owned book',
	BOOK_READING: 'book reading',
	WISHED_BOOK: 'wished book',
}