import { TYPES_ENUM } from "./TYPES";

/**
 * Enum cu numele resurselor din API in functie de type.
*/
export enum RESOURCE_NAMES_ENUM {
	ACCOUNTING_RECORD = 'accountingRecords',
	ACCOUNT = 'accounts',
	ASSET = 'assets',
	ASSETHISTORY = 'assetHistory',
	ASSET_TYPE = 'assetTypes',
	AUTHOR = 'authors',
	BOOK = 'books',
	ARTICLE = 'news',
	CONTACT = 'contact',
	CURRENCY = 'currencies',
	DAY = 'days',
	EXCHANGE_RATE = 'exchangeRates',
	EXTERNAL_ENTITY = 'external-entities',
	INGREDIENT = 'ingredients',
	LABEL = 'labels',
	LABEL_GRAPH = 'labelGraph',
	LOG_ENTRY = 'logEntries',
	LOGIN = 'login',
	MOVIE = 'movies',
	MOVIESCREENING = 'movieScreenings',
	OBJECTIVE = 'objectives',
	PHOTO = 'photos',
	PROJECT = 'projects',
	SKILL = 'skills',
	STORY = 'stories',
	TEXT = 'texts',
	TOKEN = 'verifyToken',
	TRANSACTION = 'transactions',
	USER = 'users',
	NOTE = 'notes',
	LOCALE = 'locales',
	MOVIE_GRAPH = 'movies/graph',
	TRANSACTION_GRAPH = 'transactions/graph',
	ACTIVITY_SUGGESTION = 'activities/suggestions',
	TAG = 'tags',
	RECIPE = 'recipes',
	TRANSACTION_BUNDLE = 'transactionBundles',
	DAY_STAT = 'dayStats',
	OBJECTIVE_STATS = 'objectiveStats',
	OBJECTIVE_PLAN = 'objectivePlans',
	TIMELINE_DOT = 'timelineDots',
	OWNED_BOOK = 'ownedBooks',
	BOOK_READING = 'bookReadings',
	WISHED_BOOK = 'wishedBooks',
};

export const RESOURCE_NAMES: { [key in TYPES_ENUM]: string } = {
	[TYPES_ENUM.ACCOUNTING_RECORD]: 'accountingRecords',
	[TYPES_ENUM.ACCOUNT]: 'accounts',
	[TYPES_ENUM.ASSET]: 'assets',
	[TYPES_ENUM.ASSETHISTORY]: 'assetHistory',
	[TYPES_ENUM.AUTHOR]: 'authors',
	[TYPES_ENUM.BOOK]: 'books',
	[TYPES_ENUM.ARTICLE]: 'news',
	[TYPES_ENUM.CONTACT]: 'contact',
	[TYPES_ENUM.CURRENCY]: 'currencies',
	[TYPES_ENUM.DAY]: 'days',
	[TYPES_ENUM.EXCHANGE_RATE]: 'exchangeRates',
	[TYPES_ENUM.EXTERNAL_ENTITY]: 'external-entities',
	[TYPES_ENUM.INGREDIENT]: 'ingredients',
	[TYPES_ENUM.LABEL]: 'labels',
	[TYPES_ENUM.LABEL_GRAPH]: 'labelGraph',
	[TYPES_ENUM.LOG_ENTRY]: 'logEntries',
	[TYPES_ENUM.LOGIN]: 'login',
	[TYPES_ENUM.MOVIE]: 'movies',
	[TYPES_ENUM.MOVIESCREENING]: 'movieScreenings',
	[TYPES_ENUM.OBJECTIVE]: 'objectives',
	[TYPES_ENUM.PHOTO]: 'photos',
	[TYPES_ENUM.PROJECT]: 'projects',
	[TYPES_ENUM.SKILL]: 'skills',
	[TYPES_ENUM.STORY]: 'stories',
	[TYPES_ENUM.TEXT]: 'texts',
	[TYPES_ENUM.TOKEN]: 'verifyToken',
	[TYPES_ENUM.TRANSACTION]: 'transactions',
	[TYPES_ENUM.USER]: 'users',
	[TYPES_ENUM.NOTE]: 'notes',
	[TYPES_ENUM.LOCALE]: 'locales',
	[TYPES_ENUM.MOVIE_GRAPH]: 'movies/graph',
	[TYPES_ENUM.TRANSACTION_GRAPH]: 'transactions/graph',
	[TYPES_ENUM.ACTIVITY_SUGGESTION]: 'activities/suggestions',
	[TYPES_ENUM.TAG]: 'tags',
	[TYPES_ENUM.RECIPE]: 'recipes',
	[TYPES_ENUM.TRANSACTION_BUNDLE]: 'transactionBundles',
	[TYPES_ENUM.DAY_STAT]: 'dayStats',
	[TYPES_ENUM.OBJECTIVE_STATS]: 'objectiveStats',
	[TYPES_ENUM.OBJECTIVE_PLAN]: 'objectivePlans',
	[TYPES_ENUM.TIMELINE_DOT]: 'timelineDots',
	[TYPES_ENUM.OWNED_BOOK]: 'ownedBooks',
	[TYPES_ENUM.BOOK_READING]: 'bookReadings',
	[TYPES_ENUM.WISHED_BOOK]: 'wishedBooks',
}