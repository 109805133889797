import { useState } from "react";
import { getLocale } from "../API/getLocale";
import { LOCAL_STORAGE_KEYS } from "../utils";
import { Locale } from "../context/LocaleContext";

export const useLocale = () => {
	const currentLocale = getLocale();
	const [locale, setLocale] = useState<Locale>(currentLocale);

	const selectLocale = (locale: Locale) => {
		setLocale(locale);
		localStorage.setItem(LOCAL_STORAGE_KEYS.locale, locale);
	};

	return { locale, selectLocale };
}