import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ActivityCalendar from 'react-activity-calendar';
import { REST_ADDRESS } from '../../constants/';
import { getDefaultHeaders } from '../../API/getDefaultHeaders';
import { SpinnerText } from '../SpinnerText';
import { Typography } from '../Typography';
import { DayStat } from '../../models';
import randomcolor from 'randomcolor';
import { useTheme } from '../../hooks';

export enum CONTRIBUTION_TYPES {
	github = 'github',
	gitlab = 'gitlab',
	dayStats = 'dayStats',
};

export const CONTRIBUTION_TYPES_KEYS = Object.keys(CONTRIBUTION_TYPES);
export const CONTRIBUTION_TYPES_VALUES = Object.values(CONTRIBUTION_TYPES);

const PROVIDERS: { [key: string]: string } = Object.freeze({
	github: 'https://github-contributions-api.jogruber.de/v4/andrei1902?y=last',
	gitlab: `${REST_ADDRESS}/gitlab-contributions`,
	dayStats: `${REST_ADDRESS}/contributions/dayStats/`,
});

const getAxiosConfigs = (type: CONTRIBUTION_TYPES) => {
	const typesWithToken: { [key: string]: true } = {
		[CONTRIBUTION_TYPES.dayStats]: true,
	}
	if (typesWithToken[type]) {
		return {
			headers: getDefaultHeaders()
		};
	}
	return {};
}

const COLORS = Object.freeze({
	github: '#40c463',
	gitlab: '#0f6499',
	dayStats: 'dayStats',
});

interface ActivityCalendarWrapperProps {
	hideTotalCount?: boolean;
	title?: string;
	type: CONTRIBUTION_TYPES;
	dayStat: DayStat | null;
}

export const ActivityCalendarWrapper: React.FunctionComponent<ActivityCalendarWrapperProps> = ({ type, title, hideTotalCount, dayStat }) => {
	const theme = useTheme();
	const { isLoading, data } = useContributionsData(type, dayStat);
	if (isLoading) return <SpinnerText />;

	const color = type === CONTRIBUTION_TYPES.dayStats ? randomcolor({
		luminosity: 'dark',
		format: 'hex'
	}) : COLORS[type];

	return (
		<>
			{title && <Typography align='center' variant='h6' style={{ color: theme.palette.text.primary }} >{title}</Typography>}
			<ActivityCalendar color={color} loading={isLoading} data={data.contributions} hideTotalCount={hideTotalCount} />
		</>
	);
};

const useContributionsData = (type: CONTRIBUTION_TYPES, dayStat: DayStat | null) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState({ contributions: [] });

	const url = type === CONTRIBUTION_TYPES.dayStats && dayStat ? PROVIDERS[type] + dayStat.id : PROVIDERS[type];

	useEffect(() => {
		let cancel = false;
		setIsLoading(true);
		axios.get(url, getAxiosConfigs(type)).then((result) => {
			if (cancel) return;
			setData(result.data);
			setIsLoading(false);
		});
		return () => {
			cancel = true;
		};
	}, [type, url]);

	return { isLoading, data };
};

ActivityCalendarWrapper.propTypes = {
	hideTotalCount: PropTypes.bool,
	title: PropTypes.string,
	//@ts-ignore
	type: PropTypes.oneOf(Object.keys(CONTRIBUTION_TYPES)).isRequired,
};

export default ActivityCalendarWrapper;