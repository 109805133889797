import moment from 'moment';
import { TYPES_ENUM } from '../../../API';
import { SupportedObject } from '../../../components';
import { DEFAULT_MOMENT_FORMAT, DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { User } from '../../../models/user';
import { buildGetModel } from '../../../models/buildGetModel';
import { Account } from './account';
import { Asset } from './asset';
import { TransactionType, TransactionTypeDictionary } from './transactionType';
import { ExternalEntity } from './external-entity';
import { VALID_CURRENCY } from './currency';
import { TransactionCodeLabels, TransactionCodes } from './transaction-codes';
import { Rates } from '../hooks';
import { formatPrice } from '../../../utils';

export interface Transaction extends SupportedObject {
	id: string;
	code: TransactionCodes;
	translatedCode: string;
	createdBy: string;
	date: Date;
	debitAssetId: Asset['id'];
	description: string;
	destinationId: Account['id'];
	assetId: Asset['id'];
	displayedDate: string;
	type: TransactionType;
	typeLabel: string;
	value: number;
	source: Account['id'];
	creditAssetId: Asset['id'];
	currency: VALID_CURRENCY;
}

export interface TransactionMapped extends Transaction {
	//@ts-ignore
	createdByUser: User;
	debitAsset: Asset;
	creditAsset: Asset;
	asset: Asset;
	sourceAccount: Account;
	destinationAccount: ExternalEntity | Account;
}

export interface TransactionDictionary {
	[key: Transaction['id']]: Transaction
};

export const defaultTransaction: Transaction = {
	id: '',
	assetId: '',
	code: TransactionCodes.MISC,
	translatedCode: '',
	createdBy: '',
	date: moment().toDate(),
	debitAssetId: '',
	description: '',
	destinationId: '',
	displayedDate: '',
	type: TransactionType.OUT,
	typeLabel: '',
	value: 0,
	source: '',
	creditAssetId: '',
	currency: VALID_CURRENCY.RON,
};

export const getTransaction = buildGetModel<Transaction, TransactionDictionary>(TYPES_ENUM.TRANSACTION, defaultTransaction);

export const mapTransactionForApi = (queryId: string, transaction: Transaction, user: User): any => {
	let payload: { [key: string]: any } = {
		assetId: transaction.assetId,
		code: transaction.code,
		createdBy: user.id,
		date: moment(transaction.date).format('YYYY-MM-DD'),
		debitAssetId: transaction.debitAssetId,
		creditAssetId: transaction.creditAssetId,
		description: transaction.description,
		destinationId: transaction.destinationId,
		type: transaction.type,
		value: transaction.value,
		source: transaction.source,
		currency: transaction.currency,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = transaction.id;
	}
	return payload;
};

export const mapTransactionFromAPI = (
	source: any,
	transactionTypeDictionary: TransactionTypeDictionary,
	transactionCodeLabels: TransactionCodeLabels,
): TransactionMapped => {
	const foundType = transactionTypeDictionary[source.type as TransactionType] || { label: '', value: '' };

	const result: TransactionMapped = {
		id: source.id,
		assetId: source.assetId, // TODO: this must go!
		...(source.asset && { asset: source.asset }), // TODO: this must go!
		code: source.code as TransactionCodes,
		translatedCode: transactionCodeLabels[source.code as TransactionCodes],
		createdBy: source.createdBy,
		...(source.createdByUser && { createdByUser: source.createdByUser }),
		date: moment(source.date, 'YYYY-MM-DD').toDate(),
		debitAssetId: source.debitAssetId,
		creditAssetId: source.creditAssetId,
		debitAsset: source.debitAsset,
		creditAsset: source.creditAsset,
		description: source.description,
		destinationId: source.destinationId, // TODO: this must go!
		...(source.destinationAccount && { destinationAccount: source.destinationAccount }), // TODO: this must go!
		displayedDate: moment(source.date).format(DEFAULT_MOMENT_FORMAT), // TODO: this must go!
		type: source.type,
		typeLabel: foundType.label, // TODO: this must go!
		value: source.value,
		source: source.source, // TODO: this must go!
		...(source.sourceAccount && { sourceAccount: source.sourceAccount }), // TODO: this must go!
		currency: source.currency,
	};

	return result;
};

export const mapTransactionsFromAPI = (
	sources: any[],
	transactionTypeDictionary: TransactionTypeDictionary,
	transactionCodeLabels: TransactionCodeLabels,
): Transaction[] => {
	return sources.map((source) => mapTransactionFromAPI(source, transactionTypeDictionary, transactionCodeLabels));
};

export type TransactionValueExchange = (transaction: Transaction) => number;

export const transactionValueExchangeBuilder = (rates: Rates): TransactionValueExchange => {
	return (transaction) => {
		return formatPrice(transaction.value * rates[transaction.currency]);
	};
};