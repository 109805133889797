import { cloneDeep } from 'lodash';
import moment from 'moment';
import { LabelsGraph } from '../../../hooks/useLabels';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';
export const DATE_MOMENT_FORMAT = 'YYYY-MM-DD';

export enum ObjectiveStatuses {
	todo = 0,
	done = 1,
	overdue = 2,
	failed = 3,
	abandoned = 4,
}

export enum ObjectivePriorities {
	low = 0,
	medium = 1,
	high = 2,
	critical = 3,
}


export enum UpdateTypes {
	finish = 'finish',
	unfinish = 'unfinish',
	fail = 'fail',
	unfail = 'unfail',
	abandon = 'abandon',
	unAbandon = 'unAbandon',
};

export const buildPrioritySelectorOptions = (labels: LabelsGraph) => {
	const options = [];
	for (const [key, value] of Object.entries(ObjectivePriorities)) {
		options.push({
			value,
			label: key,
		});
	}
	return options;
};

/**
 * Returns default UI object
 * @deprected
*/
export const getDefaultObjective = () => {
	return { ...defaultObjective };
};

/**
 * Turns an object from the API intro an object for the UI
 * @deprected
*/
export const formatFromAPIObjective = (o: any) => {
	o.dueDate = o.dueDate ? moment(o.dueDate).format(DATE_MOMENT_FORMAT) : '';
	o.finishedDate = o.finishedDate ? moment(o.finishedDate).format(DATE_MOMENT_FORMAT) : '';
	return cloneDeep(o);
};

export const formatToAPI = (o: any) => {
	o.dueDate = o.dueDate ? moment(o.dueDate, DATE_MOMENT_FORMAT).toISOString() : null;
	o.finishedDate = o.finishedDate ? moment(o.finishedDate, DATE_MOMENT_FORMAT).toISOString() : null;
	delete o.displayedTags;
	return cloneDeep(o);
};

/**
 * Returns true if objective is completed
*/
export const assertIsCompleted = (o: Objective) => {
	return o.status === ObjectiveStatuses.done || o.status === ObjectiveStatuses.overdue;
};

/**
 * Returns true if objective is completed
 * @param {{ status: number }} o the objective.
*/
export const assertIsOver = (o: Objective) => {
	return assertIsCompleted(o) || assertIsFailed(o);
};

/**
 * Flips the status of the objective and returns it.
 * @param {{ status: number, finishedDate: string, dueDate: string }} o the API object.
*/
export const flipStatus = (o: Objective) => {
	const result = { ...o };
	if (assertIsCompleted(o)) {
		result.finishedDate = '';
		result.status = ObjectiveStatuses.todo;
		return result;
	}
	result.finishedDate = moment().format(DATE_MOMENT_FORMAT);
	const isOverdue = assertIsOverdue(result);
	if (isOverdue) {
		result.status = ObjectiveStatuses.overdue;
	} else {
		result.status = ObjectiveStatuses.done;
	}
	return result;
};

export const failObjective = (o: Objective) => {
	const result = { ...o };
	result.finishedDate = moment().format(DATE_MOMENT_FORMAT);
	result.status = ObjectiveStatuses.failed;
	return result;
};

export const unfailObjective = (o: Objective) => {
	const result = { ...o };
	result.finishedDate = '';
	result.status = ObjectiveStatuses.todo;
	return result;
};

export const assertIsFailed = (o: Objective) => {
	return o.status === ObjectiveStatuses.failed;
};

/**
 * Returns true if status is overdue.
 * @param {{ finishedDate: string, dueDate: string, status: number }} o the objective.
*/
export const assertIsOverdue = (o: Objective) => {
	return !assertIsCompleted(o) && (moment(o.finishedDate).isAfter(o.dueDate) || o.status === ObjectiveStatuses.overdue || moment(moment()).isAfter(o.dueDate));
};

/**
 * Asserts if this objective is untargeted
 * @param {{ dueDate: string }} objective the objective.
*/
export const assertIsTargeted = (objective: Objective) => {
	return !!objective.dueDate;
};

export interface Objective {
	id: string,
	points: number,
	text: string,
	description: string,
	dueDate: string,
	finishedDate: string,
	priority: ObjectivePriorities,
	status: ObjectiveStatuses,
	tags: Tag['id'][],
	displayedTags: string,
}

export interface ObjectiveDictionary {
	[key: Objective['id']]: Objective
};

export const defaultObjective: Objective = {
	id: '',
	points: 0,
	text: '',
	description: '',
	dueDate: '',
	finishedDate: '',
	priority: ObjectivePriorities.low,
	status: ObjectiveStatuses.todo,
	tags: [],
	displayedTags: '',
};

export const getObjective = (source: any, objectiveDictionary: ObjectiveDictionary): Objective => {
	//@ts-ignore
	const found = objectiveDictionary[source];
	if (!found) {
		console.warn(`No objective type found for "${source}"`, source, objectiveDictionary);
	}
	return found || defaultObjective;
}

export const mapObjectiveForApi = (_queryId: string, objective: Objective): any => {
	const dueDate = objective.dueDate ? moment(objective.dueDate, DATE_MOMENT_FORMAT).toISOString() : null;
	const finishedDate = objective.finishedDate ? moment(objective.finishedDate, DATE_MOMENT_FORMAT).toISOString() : null;
	let payload: { [key: string]: any } = {
		id: objective.id,
		points: objective.points,
		text: objective.text,
		description: objective.description,
		dueDate: dueDate,
		finishedDate: finishedDate,
		priority: objective.priority,
		status: objective.status,
		tags: objective.tags,
	};
	return payload;
};

export const mapObjectiveFromAPI = (
	source: any,
	tagDictionary: TagDictionary,
): Objective => {
	const displayedTags = (source.tags || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	const dueDate = source.dueDate ? moment(source.dueDate).format(DATE_MOMENT_FORMAT) : '';
	const finishedDate = source.finishedDate ? moment(source.finishedDate).format(DATE_MOMENT_FORMAT) : '';

	return {
		description: source.description,
		points: source.points,
		displayedTags: displayedTags,
		dueDate: dueDate,
		finishedDate: finishedDate,
		id: source.id,
		priority: source.priority,
		status: source.status,
		tags: source.tags,
		text: source.text,
	}
};

export const mapObjectivesFromAPI = (
	sources: any[],
	tagDictionary: TagDictionary,
): Objective[] => {
	return sources.map((source) => mapObjectiveFromAPI(source, tagDictionary));
};