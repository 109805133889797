import { Option } from '../context';
import { ObjectivePriorities } from '../models/';
import { LocaleDictionary, LocalesType } from '../models/locales';
import { Platform, PlatformType } from '../Apps/MoviesApp/models/movieScreening';
import { LabelsGraph } from './useLabels';
import { MovieType, MovieTypes } from '../Apps/MoviesApp/models';

type ResponseResult = {
	movieTypeDictionary: { [key in MovieTypes]: MovieType };
	platformDictionary: { [key in PlatformType]: Platform };
	platformOptions: Option[];
	localeDictionary: LocaleDictionary;
	localesOptions: Option[];
	movieTypeOptions: Option[];
	priorityOptions: Option[];
};

export const useEnums = (
	labels: LabelsGraph
): ResponseResult => {
	const movieTypeDictionary = {
		1: {
			value: 1,
			label: labels.movie,
		},
		2: {
			value: 2,
			label: labels.series,
		},
		3: {
			value: 3,
			label: labels.documentary,
		},
		4: {
			value: 4,
			label: labels.standup,
		},
	};

	const movieTypeOptions = [
		{
			value: 1,
			label: labels.movie,
		},
		{
			value: 2,
			label: labels.series,
		},
		{
			value: 3,
			label: labels.documentary,
		},
		{
			value: 4,
			label: labels.standup,
		},
	];

	const platformDictionary = {
		1: {
			value: 1,
			label: labels.netflix,
		},
		2: {
			value: 2,
			label: labels.filelist,
		},
		3: {
			value: 3,
			label: labels.cinema,
		},
	}

	const platformOptions: Option[] = [
		{
			value: 1,
			label: labels.netflix,
		},
		{
			value: 2,
			label: labels.filelist,
		},
		{
			value: 3,
			label: labels.cinema,
		},
	];

	const localeDictionary: LocaleDictionary = {
		[LocalesType.ro]: {
			label: labels.romanianLanguage,
			value: LocalesType.ro,
		},
		[LocalesType.en]: {
			label: labels.englishLanguage,
			value: LocalesType.en,
		},
		[LocalesType.es]: {
			label: labels.spanishLanguage,
			value: LocalesType.en,
		},
		[LocalesType.fr]: {
			label: labels.frenchLanguage,
			value: LocalesType.fr,
		},
		[LocalesType.it]: {
			label: labels.italianLanguage,
			value: LocalesType.it,
		},
	}

	const localesOptions: Option[] = [
		{
			label: labels.romanianLanguage,
			value: LocalesType.ro,
		},
		{
			label: labels.englishLanguage,
			value: LocalesType.en,
		},
		{
			label: labels.spanishLanguage,
			value: LocalesType.es,
		},
		{
			label: labels.frenchLanguage,
			value: LocalesType.fr,
		},
		{
			label: labels.italianLanguage,
			value: LocalesType.it,
		},
	];

	const priorityOptions = Object.keys(ObjectivePriorities).map((field) => {
		return {
			label: field,
			//@ts-ignore
			value: ObjectivePriorities[field]
		}
	})

	return {
		movieTypeDictionary,
		platformDictionary,
		platformOptions,
		localeDictionary,
		localesOptions,
		movieTypeOptions,
		priorityOptions
	};
}