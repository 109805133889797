import { TYPES_ENUM } from '../../../API';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { ObjectFieldValidator } from '../../../validators';
import { Book, defaultBook } from './book';
import { buildGetModel } from '../../../models/buildGetModel';
import { User, defaultUser } from '../../../models';

export interface OwnedBook extends SuportedObject {
	id: string;
	details: string,
	dateIn: Date,
	book: Book,
	user: User,
};

export interface OwnedBookDictionary {
	[key: OwnedBook["id"]]: OwnedBook;
};

export const defaultOwnedBook: OwnedBook = {
	id: '',
	details: '',
	dateIn: new Date(),
	book: { ...defaultBook },
	user: { ...defaultUser },
};

export const getOwnedBook = buildGetModel<OwnedBook, OwnedBookDictionary>(TYPES_ENUM.OWNED_BOOK, defaultOwnedBook);

export const validateOwnedBookField: ObjectFieldValidator<OwnedBook> = (fieldName, value) => {
	if (fieldName === 'dateIn' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	return {
		isValid: true,
		messageId: '',
	}
}