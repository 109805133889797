import React, { useState } from 'react';
import CustomSelector, { CustomSelectorProps } from '../CustomSelector/CustomSelector';
import { useContext } from 'react';
import { LabelsContext, UsersContext } from '../../context';
import { FilterChipsProps } from '../FilterChips';
import { TagLike, UseChipsResult } from '../FilterChips/useFilterChipsState';
import { UserSelectorChips } from './user-selector-chips';

export enum UserRenderTypes {
	Selector = 'Selector',
	Chips = 'Chips',
}

export interface UserSelectorProps extends CustomSelectorProps {
	handleChange: (field: string, value: string | string[]) => void;
	value: string | string[];
	renderType?: UserRenderTypes;
	chipsHook?: UseChipsResult<TagLike>;
	handleUserChipsChange?: FilterChipsProps<TagLike>['onChipsClickHadler'];
}

export const UserSelector: React.FunctionComponent<UserSelectorProps> = ({ className, disabled, value, handleChange, multiple, renderType, chipsHook, handleUserChipsChange }) => {
	// TODO: Fix Props (don't extend CustomSelectorProps if you'll use your own)
	const [selectedUser, setSelectedUser] = useState(value);
	const { labels } = useContext(LabelsContext);
	const { users } = useContext(UsersContext);
	const userSelectorOptions = users.map((user) => ({ value: user.id, label: user.email }));

	const onChange = (field: string, value: string) => {
		setSelectedUser(value as string);
		handleChange(field, value as string);
	};

	if (renderType === UserRenderTypes.Chips && chipsHook && handleUserChipsChange) {
		return (
			<UserSelectorChips
				chipsHook={chipsHook}
				handleUserChipsChange={handleUserChipsChange}
			/>
		);
	}

	return (
		<CustomSelector
			id="UserSelector"
			name="createdBy"
			className={className}
			label={labels.createdBy}
			value={selectedUser}
			options={userSelectorOptions}
			handleChange={onChange}
			disabled={disabled}
			multiple={multiple}
		/>
	);
}

export default UserSelector;