/**
 * Returns the average from a list of objects.
*/
export const getAverage = <T>(list: T[], fieldName: keyof T) => {
	if (!list.length) return 0;
	const result = list.reduce((result, item) => {
		const value = item[fieldName] as any;
		if (typeof value === 'number') {
			return result += value;
		} else {
			console.warn('You are averaging a non numeric field, dumbass!');
			return 0;
		}
	}, 0) / list.length;
	return result;
};

export default getAverage;