import './CardsPage.css';
import React, { useContext } from 'react';
import { withGrowAnimation } from '../../HOC';
import { SimpleYesNoDialog, PageTitle, SpinnerText, Container, Grid } from '..';
import { LabelsContext } from '../../context';

interface CardsPageProps {
	title?: string;
	isLoading: boolean;
	FiltersComponent?: JSX.Element;
	ButtonsComponent?: JSX.Element;
	SortingComponent?: JSX.Element;
	CardsList?: JSX.Element[];
	PaginationComponent?: JSX.Element;
	showDeleteModal: boolean;
	handleDelete: () => void;
	handleConfirmDelete: () => Promise<void>;
	deleteAlertText: string;
}

export const CardsPage: React.FunctionComponent<CardsPageProps> = (props) => {
	return (
		<Container component="main" maxWidth="lg" className="cards-page-container">
			<Grid container spacing={3}>
				{renderTitle(props)}
				{renderSpinners(props)}
				{renderButtons(props)}
				{renderFilters(props)}
				{renderSorting(props)}
				<Grid item xs={12}>
					<Grid container className="cards-page-content" spacing={3}>
						{renderCards(props)}
						{props.children}
					</Grid>
				</Grid>
				{renderPagination(props)}
			</Grid>
			<DeleteModal {...props} />
		</Container>
	);
}

export default CardsPage;

const renderTitle = ({ title }: CardsPageProps) => {
	if (title) {
		return (
			<Grid item xs={12}>
				<PageTitle text={title} />
			</Grid>
		);
	}
	return null;
};

const renderSpinners = ({ isLoading }: CardsPageProps) => {
	if (isLoading) {
		return withGrowAnimation(
			<Grid item xs={12}>
				<SpinnerText />
			</Grid>
		);
	}
	return null;
};

const renderFilters = ({ FiltersComponent }: CardsPageProps) => {
	if (FiltersComponent) {
		return (
			<Grid item xs={12} className="cards-page--centered">
				{FiltersComponent}
			</Grid>
		);
	}
	return null;
};

const renderSorting = ({ SortingComponent }: CardsPageProps) => {
	if (SortingComponent) {
		return (
			<Grid item xs={12} className="cards-page--centered">
				{SortingComponent}
			</Grid>
		);
	}
	return null;
};

const renderButtons = ({ ButtonsComponent }: CardsPageProps) => {
	if (ButtonsComponent) {
		return (
			<Grid item xs={12} className="cards-page--centered">
				{ButtonsComponent}
			</Grid>
		);
	}
	return null;
};

const renderCards = ({ CardsList }: CardsPageProps) => {
	if (CardsList) return CardsList;
	return null;
};

const renderPagination = ({ PaginationComponent }: CardsPageProps) => {
	if (PaginationComponent) {
		return (
			<Grid item xs={12} className="cards-page--centered">
				{PaginationComponent}
			</Grid>
		);
	}
	return null;
};

const DeleteModal: React.FunctionComponent<CardsPageProps> = ({ showDeleteModal, handleDelete, deleteAlertText, handleConfirmDelete }) => {
	const { labels } = useContext(LabelsContext);

	return (
		<SimpleYesNoDialog
			cancelButton={labels.back}
			confirmButton={labels.delete}
			handleConfirm={handleConfirmDelete}
			handleClose={handleDelete}
			open={showDeleteModal}
			text={deleteAlertText}
			title={labels.payAttention}
		/>
	);
}