import './ButtonRow.css';
import React from 'react';
import { Button, ButtonProps } from '../Button';

export interface ButtonDefinition {
	variant: ButtonProps["variant"];
	color: ButtonProps["color"];
	onClick: () => void;
	disabled: boolean;
	icon: JSX.Element;
	text: string;
	size?: ButtonProps["size"]
}

interface ButtonRowProps {
	buttons: ButtonDefinition[];
}

export const ButtonRow: React.FunctionComponent<ButtonRowProps> = ({ buttons }) => {
	return (
		<div className="button-row">
			{
				buttons.map((b, i) => {
					return (
						<Button
							key={`Button-${i}`}
							variant={b.variant}
							onClick={b.onClick}
							disabled={b.disabled}
						>
							{b.icon}
							{b.text}
						</Button>
					)
				})
			}
		</div>
	);
}