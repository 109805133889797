import { TableRow } from '@mui/material';
import { RowSelectionCell } from './RowSelectionCell';
import { CustomCell } from './CustomCell';
import { CustomRowMobile } from './custom-row-mobile';
import { Column, SupportedRowType } from './CustomTable.types';
import { SelectionType, Selector } from './CustomTable';
import { getIsSelected } from './custom-table-helpers';

interface CustomRowProps<T extends SupportedRowType = SupportedRowType> {
	disabled: boolean;
	selectedLines: T[];
	row: T;
	index: number;
	columns: Column[];
	isMobile?: boolean;
	selector: Selector<T>;
}

export const CustomRow = <T extends SupportedRowType = SupportedRowType>(props: CustomRowProps<T>): JSX.Element => {
	const { selectedLines, row, index, disabled, isMobile, columns, selector } = props;

	const handleLineClick = (row: T) => {
		if (disabled) return () => { };
		if (selector.selectionType === SelectionType.single) {
			return () => {
				selector.overwriteSelected([row]);
			};
		}
		return () => {
			selector.selectLines([row]);
		};
	};

	const onLineClick = handleLineClick(row);

	let isItemSelected = getIsSelected(row, selectedLines || []);

	if (isMobile) {
		return (
			<CustomRowMobile
				selectedLines={selectedLines}
				row={row}
				index={index}
				onLineClick={onLineClick}
				columns={columns}
			/>
		);
	}

	const key = row.id;

	return (
		<TableRow
			hover
			role="checkbox"
			key={key}
			onClick={onLineClick}
		>
			<RowSelectionCell isItemSelected={isItemSelected} disabled={disabled} onSelect={onLineClick} />
			{
				(columns || []).map((column, i) => {
					return (
						<CustomCell key={`row-${index}-column-${i}`}
							columnIndex={i}
							row={row}
							rowIndex={index}
							column={column}
						/>
					);
				})
			}
		</TableRow>
	);
};