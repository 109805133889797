import { HeaderItemDefinition } from '../../../../components/Header/itemDefinitions';
import { Roles } from '../../../../utils';

export const BOOK_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'books',
		url: '/books',
		roles: { [Roles.visitor]: true, [Roles.admin]: true, [Roles.superUser]: true }
	},
	{
		labelName: 'authors',
		url: '/authors',
		roles: { [Roles.visitor]: true, [Roles.admin]: true, [Roles.superUser]: true }
	},
];

export const MY_BOOKS_DEFINITIONS: HeaderItemDefinition[] = [
	{
		labelName: 'bookReadings',
		url: '/bookReadings',
		roles: { [Roles.visitor]: true, [Roles.admin]: true, [Roles.superUser]: true }
	},
	{
		labelName: 'ownedBooks',
		url: '/ownedBooks',
		roles: { [Roles.visitor]: true, [Roles.admin]: true, [Roles.superUser]: true }
	},
	{
		labelName: 'wishedBooks',
		url: '/wishedBooks',
		roles: { [Roles.visitor]: true, [Roles.admin]: true, [Roles.superUser]: true }
	},
];