import './header.css';
import React, { useContext } from 'react';
import { MenuLink, ContextMenu, MenuLinkProps, MenuuItemProps } from '..';
import { useHistory } from 'react-router';
import { LabelsContext, LocaleContext, Locale } from '../../context';

interface HeaderProps {
	links: MenuLinkProps["link"][];
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	// TODO: hooks, labels
	const [anchorEl, setAnchorEl] = React.useState<Element>();
	const history = useHistory();
	const { locale, selectLocale } = useContext(LocaleContext);
	const isMenuOpen = Boolean(anchorEl);

	const { labels } = useContext(LabelsContext);

	const handleProfileMenuOpen = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	}

	const handleMenuClose = () => {
		setAnchorEl(undefined);
	};

	const selectLanguage = (lang: Locale) => () => {
		selectLocale(lang);
		handleMenuClose();
	};

	let items: MenuuItemProps[] = [
		{
			onClick: selectLanguage,
			text: 'RO',
			params: 'RO'
		},
		{
			onClick: selectLanguage,
			text: 'EN',
			params: 'EN'
		},
		{
			onClick: selectLanguage,
			text: 'FR',
			params: 'FR'
		}
	];

	const onTitleClick = () => history.push('/');

	return (
		<div id="Header">
			<div className="section-desktop">
				<div className="navbar">
					<h1 className="title" onClick={onTitleClick}>{labels.name}</h1>
					<div className="navigator">
						<div className="navigator">
							{props.links.map((link, index) => {
								return <MenuLink key={index} link={link} />
							})}
						</div>
						<div>
							<ContextMenu
								id="context-menu"
								locale={locale}
								onClick={handleProfileMenuOpen}
								anchorEl={anchorEl}
								open={isMenuOpen}
								onClose={handleMenuClose}
								items={items}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="section-mobile">
				<div className="navbar">
					<h1 className="title" onClick={onTitleClick}>{labels.name}</h1>
					<div>
						<ContextMenu
							locale={locale}
							id="context-menu"
							onClick={handleProfileMenuOpen}
							anchorEl={anchorEl}
							open={isMenuOpen}
							onClose={handleMenuClose}
							items={items}
						/>
					</div>
				</div>
				<div className="vertical">
					{props.links.map((link, index) => {
						return <MenuLink key={index} link={link} />
					})}
				</div>
			</div>
		</div>
	);
}

export default Header;