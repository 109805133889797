import { defaultUser, getUser, User, UserDictionary } from '../../../models';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { SuportedObject } from "../../../utils/filterListByFilters";
import { Account, AccountDictionary, defaultAccount, getAccount } from './account';
import { buildGetModel } from '../../../models/buildGetModel';
import { TYPES_ENUM } from '../../../API';

export interface AccountingRecord extends SuportedObject {
	id: string,
	date: Date,
	creditAccountId: Account['id'],
	creditAccount: Account,
	debitAccountId: Account['id'],
	debitAccount: Account,
	value: number,
	details: string,
	userId: User['id'],
	user: User,
}

export interface AccountingRecordDictionary {
	[key: AccountingRecord['id']]: AccountingRecord
};

export const defaultAccountingRecord: AccountingRecord = {
	id: '',
	creditAccount: { ...defaultAccount },
	creditAccountId: '',
	date: new Date(),
	debitAccount: { ...defaultAccount },
	debitAccountId: '',
	details: '',
	user: { ...defaultUser },
	userId: '',
	value: 0,
};

export const getAccountingRecord = buildGetModel<AccountingRecord, AccountingRecordDictionary>(TYPES_ENUM.ACCOUNTING_RECORD, defaultAccountingRecord);

export const mapAccountingRecordForApi = (queryId: string, accountingRecord: AccountingRecord): any => {
	let payload: { [key: string]: any } = {
		date: accountingRecord.date,
		creditAccountId: accountingRecord.creditAccountId,
		debitAccountId: accountingRecord.debitAccountId,
		value: accountingRecord.value,
		details: accountingRecord.details,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = accountingRecord.id
	}
	return payload;
};

export const mapAccountingRecordFromAPI = (
	source: any,
	accountDictionary: AccountDictionary,
	userDictionary: UserDictionary
): AccountingRecord => {
	const foundCreditAccount = getAccount(source.creditAccountId, accountDictionary);
	const foundDebitAccount = getAccount(source.debitAccountId, accountDictionary);
	const foundUser = getUser(source.userId, userDictionary);
	return {
		creditAccount: foundCreditAccount,
		creditAccountId: foundCreditAccount.id,
		date: source.date,
		debitAccount: foundDebitAccount,
		debitAccountId: foundDebitAccount.id,
		details: source.details,
		id: source.id,
		user: foundUser,
		userId: source.userId,
		value: source.value,
	} as AccountingRecord;
};

export const mapAccountingRecordsFromAPI = (
	accountsFromAPI: any[],
	accountDictionary: AccountDictionary,
	userDictionary: UserDictionary
): AccountingRecord[] => {
	return accountsFromAPI.map((source) => mapAccountingRecordFromAPI(source, accountDictionary, userDictionary));
};