import { AllowedRoles } from "../HOC";
import { Roles } from "./Roles";

/**
 * Turns a list of roles into a roles dictionary
*/
export const buildRolesDictionary = (roles: Roles[]): AllowedRoles => {
	const result: AllowedRoles = {
		admin: false,
		superUser: false,
		visitor: false
	};
	Object.values(Roles).forEach(role => {
		const found = roles.find(r => r === role);
		result[role] = !!found;
	});
	return result;
};