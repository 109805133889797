import './components/common.css';
import { Suspense, lazy, useState } from 'react';
import { Preloader } from './components';
import { getPreferredColorScheme } from './utils';
import { CssBaseline as MuiCssBaseline, ThemeProvider as MuiThemeProvider, createTheme as muiCreateTheme } from '@mui/material';
import { useErrors, useLabels, useLocale, useUser } from './hooks';
import { AppContext, ErrorContext, LabelsContext, LocaleContext, UserContext } from './context';
import { App } from './AppEnum';
import BooksApp from './Apps/BooksApp/BooksApp';
import { useHistory } from 'react-router';
import { getApp, setApp } from './LocalStorage';
import MoviesApp from './Apps/MoviesApp/MoviesApp';
import RecipesApp from './Apps/ReceipesApp/RecipesApp';

const PublicApp = lazy(() => import('./PublicApp'));
const AdminApp = lazy(() => import('./AdminApp'));
const ProgressApp = lazy(() => import('./Apps/ProgressApp/ProgressApp'));
const FinanceAppWrapper = lazy(() => import('./Apps/FinanceApp/finance-app-wrapper'));
const HealthApp = lazy(() => import('./Apps/HealthApp/HealthApp'));
const MinimalistApp = lazy(() => import('./Apps/MinimaListApp/MinimaListApp'));

const Main = () => {
	const [user, isAdmin, isLoadingUser, , login, logout] = useUser();
	const [currentApp, setCurrentApp] = useState<App>(getApp());
	const { locale, selectLocale } = useLocale();
	const [error, updateError] = useErrors();
	const [muiTheme] = useState(muiCreateTheme({
		palette: {
			mode: getPreferredColorScheme(),
		}
	}));
	const history = useHistory();

	const { isLoadingLabels, labels } = useLabels(locale);

	const selectApp = (app: App) => {
		setCurrentApp(app);
		history.push('/');
		setApp(app);
	};

	const handleExit = () => {
		selectApp(App.none);
	};

	const renderContent = () => {
		if (isLoadingUser || isLoadingLabels) return <Preloader />;
		if (user.id) {
			if (currentApp === App.books) return <BooksApp />;
			if (currentApp === App.finance) return <FinanceAppWrapper />;
			if (currentApp === App.movies) return <MoviesApp />;
			if (currentApp === App.recipes) return <RecipesApp />;
			if (currentApp === App.progress) return <ProgressApp />;
			if (currentApp === App.health) return <HealthApp />;
			if (currentApp === App.minimalist) return <MinimalistApp />;
			return (
				<AdminApp />
			);
		}
		return <PublicApp />;
	};

	return (
		<MuiThemeProvider theme={muiTheme}>
			<LocaleContext.Provider value={{ locale, selectLocale }}>
				<UserContext.Provider value={{ isAdmin, isLoadingUser, user, login, logout }}>
					<AppContext.Provider
						value={{
							app: currentApp,
							handleExit: handleExit,
							selectApp: selectApp,
						}}
					>
						{/* Split labels for each App so move this 1 level below */}
						<LabelsContext.Provider value={{ isLoadingLabels: isLoadingLabels, labels: labels }}>
							<ErrorContext.Provider value={{ error, updateError }}>
								<MuiCssBaseline />
								<Suspense fallback={<Preloader />}>
									{renderContent()}
								</Suspense>
							</ErrorContext.Provider>
						</LabelsContext.Provider>
					</AppContext.Provider>
				</UserContext.Provider>
			</LocaleContext.Provider>
		</MuiThemeProvider>
	);
};

export default Main;