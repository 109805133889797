import { Option } from '../../../context';

type TransactionTypeLabels = { encashment: string, payment: string, internalTransfer: string };

export enum TransactionType {
	'IN' = 'IN',
	'OUT' = 'OUT',
	'INTERNAL_TRANSFER' = 'INTERNAL_TRANSFER',
}

export type TransactionTypeDictionary = { [key in TransactionType]: { value: TransactionType, label: string } };

export const buildTransactionTypes = (labels: TransactionTypeLabels): Option[] => {
	return [
		{
			value: 'IN',
			label: labels.encashment
		},
		{
			value: 'OUT',
			label: labels.payment
		},
		{
			value: 'INTERNAL_TRANSFER',
			label: labels.internalTransfer
		},
	];
};

export const buildTransactionTypeDictionary = (labels: TransactionTypeLabels): TransactionTypeDictionary => {
	return {
		[TransactionType.IN]: {
			value: TransactionType.IN,
			label: labels.encashment
		},
		[TransactionType.OUT]: {
			value: TransactionType.OUT,
			label: labels.payment
		},
		[TransactionType.INTERNAL_TRANSFER]: {
			value: TransactionType.INTERNAL_TRANSFER,
			label: labels.internalTransfer
		},
	};
};