import React from 'react';
import { CircularProgress, Typography } from '..';
import { Paper } from '../Paper';
import { DEFAULT_ELEVATION } from '../../constants';

interface Props {
	isDbz?: boolean;
}

export const Preloader: React.FunctionComponent<Props> = ({ isDbz }) => {
	return (
		<Paper elevation={DEFAULT_ELEVATION} className="margin--one-rem display-flex--column align-items--center">
			{isDbz ? (
				<>
					<img src="/powerup.gif" alt="Gif of Goku powering up" />
					<Typography variant="h4" align="center" color="textPrimary" gutterBottom>
						Powering up...
					</Typography>
				</>
			) : (
				<>
					<Typography variant="h4" component="h4" align="center" color="textPrimary" gutterBottom>
						Warming up Heroku server... Wait just a second!
					</Typography>
					<CircularProgress className="margin--one-rem" />
				</>
			)}
		</Paper>
	);
};
