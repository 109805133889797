import { Objective, ObjectivePriorities, ObjectiveStatuses } from './objective';

interface ObjectiveValues {
	count: number;
	points: number;
	score: number;
}

export interface ObjectiveStats {
	scores: { [key: Objective['id']]: ObjectiveValues };
	stats: {
		byPriority: { [key in ObjectivePriorities]: ObjectiveValues };
		byStatus: { [key in ObjectiveStatuses]: ObjectiveValues };
		byDay: {
			[key: string]: {
				dueDate: ObjectiveValues;
				finishedDate: ObjectiveValues;
			}
		};
		totals: {
			points: number;
			count: number;
			score: number;
		};
	}
}

export const defaultObjectiveStats: ObjectiveStats = {
	scores: {},
	stats: {
		byPriority: {
			0: {
				points: 0,
				count: 0,
				score: 0,
			},
			1: {
				points: 0,
				count: 0,
				score: 0,
			},
			2: {
				points: 0,
				count: 0,
				score: 0,
			},
			3: {
				points: 0,
				count: 0,
				score: 0,
			}
		},
		byStatus: {
			0: {
				points: 0,
				count: 0,
				score: 0,
			},
			1: {
				points: 0,
				count: 0,
				score: 0,
			},
			2: {
				points: 0,
				count: 0,
				score: 0,
			},
			3: {
				points: 0,
				count: 0,
				score: 0,
			},
			4: {
				points: 0,
				count: 0,
				score: 0,
			}
		},
		byDay: {},
		totals: {
			points: 0,
			count: 0,
			score: 0,
		},
	},
};

export const mapObjectiveStatsForApi = (objectives: Objective[]): any => {
	const initial: { [key: Objective['id']]: boolean } = {};
	let payload: { [key: Objective['id']]: boolean } = objectives.reduce((prev, objective) => {
		prev[objective.id] = true;
		return prev;
	}, initial);
	return payload;
};

export const mapObjectiveStatsFromAPI = (
	source: any,
): ObjectiveStats => {
	return source as ObjectiveStats;
};