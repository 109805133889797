import moment from "moment";
import { DEFAULT_NEW_QUERY_ID } from "../../../constants";
import { formatPrice, getPercentage } from "../../../utils";
import { SuportedObject } from "../../../utils/filterListByFilters";
import { DayStat, defaultDayStat, mapDayStatsFromAPI } from "./daysStat";
import { UserDictionary, defaultUser } from "../../../models/user";

export interface Result {
	id: string;
	value: string | boolean;
	valueNumeric: number;
	score: number;
	target: string | boolean;
	targetNumeric: number;
}

export interface Day extends SuportedObject {
	id: string;
	key: string,
	date: string;
	objectives: DayStat[],
	results: Result[],
	score: number,
	displayedScore: string,
	happiness: number,
	createdBy: string,
	createdByEmail: string,
	details: string,
};


export interface DayDictionary {
	[key: Day['key']]: Day
};

export const defaultDay: Day = {
	id: '',
	key: '',
	date: '',
	objectives: [],
	results: [],
	score: 0,
	displayedScore: '0%',
	happiness: 0,
	createdBy: '',
	createdByEmail: '',
	details: '',
};

export const mapDayForApi = (queryId: string, day: Day): any => {
	let payload: { [key: string]: any } = {
		id: day.id,
		objectives: day.objectives,
		results: day.results,
		score: day.score,
		happiness: day.happiness,
		createdBy: day.createdBy,
		details: day.details,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.key = day.key
	}
	return payload;
};

export const mapDayFromAPI = (source: any, userDictionary: UserDictionary) => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };

	const mapResult = (sourceResult: { id: string; value: string | boolean; score: number; target: string | boolean; }): Result => {
		let targetNumeric = 0;
		let valueNumeric = 0;
		if (typeof sourceResult.target === 'boolean') {
			targetNumeric = sourceResult.target ? 1 : 0;
		}
		if (typeof sourceResult.target === 'string') {
			targetNumeric = Number(sourceResult.target);
		}
		if (typeof sourceResult.value === 'boolean') {
			valueNumeric = sourceResult.value ? 1 : 0;
		}
		if (typeof sourceResult.value === 'string') {
			valueNumeric = Number(sourceResult.value);
		}
		return {
			id: sourceResult.id,
			value: sourceResult.value,
			valueNumeric: valueNumeric,
			score: sourceResult.score,
			target: sourceResult.target,
			targetNumeric: targetNumeric,
		};
	};

	const result: Day = {
		id: source.id,
		key: source.key,
		date: moment(source.id, 'DD-MM-YYYY').format('YYYY-MM-DD'),
		objectives: mapDayStatsFromAPI(source.objectives, userDictionary),
		results: (source.results || []).map((result: any) => mapResult(result)),
		score: formatPrice(source.score),
		displayedScore: getPercentage(source.score),
		happiness: source.happiness,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		details: source.details,
	};

	result.objectives.forEach(objective => {
		const foundResult = result.results.find(result => result.id === objective.id) || { ...defaultDayStat };
		result[objective.title] = getPercentage(foundResult.score || 0)
	});

	return result as Day;
};

export const mapDaysFromAPI = (sources: any[], userDictionary: UserDictionary): Day[] => {
	return sources.map((source) => mapDayFromAPI(source, userDictionary));
};

export const isDay = (object: Object): boolean => {
	return object.hasOwnProperty('happiness') && object.hasOwnProperty('score');
}