import { LabelsGraph } from "../../../hooks";
import { Option } from "../../../context";

export enum TransactionCodes {
	BILLS = 'BILLS',
	TRANSPORT = 'TRANSPORT',
	PARTY = 'PARTY',
	THREADS = 'THREADS',
	MISC = 'MISC',
	FOOD = 'FOOD',
	HOME = 'HOME',
	SALARY = 'SALARY',
	PROJECT = 'PROJECT',
	CARRER = 'CARRER',
	HEALTH = 'HEALTH',
	GAMES = 'GAMES',
	TRAVEL = 'TRAVEL',
	BANKS = 'BANKS',
	INVESTMENTS = 'INVESTMENTS',
	INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
}

export type TransactionCodeLabels = { [key in TransactionCodes]: string };

export const getTransactionCodeLabels = (labels: LabelsGraph): TransactionCodeLabels => {
	return {
		BANKS: labels.categoryBanks,
		BILLS: labels.categoryBills,
		CARRER: labels.categoryCareer,
		FOOD: labels.categoryFood,
		GAMES: labels.categoryGames,
		HEALTH: labels.categoryHealth,
		HOME: labels.categoryHome,
		INTERNAL_TRANSFER: labels.internalTransfer,
		INVESTMENTS: labels.categoryInvestments,
		MISC: labels.categoryMisc,
		PARTY: labels.categoryParty,
		PROJECT: labels.categoryProject,
		SALARY: labels.categoryEmployer,
		THREADS: labels.categoryClothes,
		TRANSPORT: labels.categoryTransport,
		TRAVEL: labels.categoryTravel,
	};
};

export const getTransactionCodeSelectorOptions = (labels: LabelsGraph): Option[] => {
	const transactionCodeLabels = getTransactionCodeLabels(labels);
	return Object.values(TransactionCodes).map(transactionCode => ({ label: transactionCodeLabels[transactionCode], value: transactionCode }));
};