import moment from 'moment';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { DEFAULT_MOMENT_FORMAT, DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { getLocale, LocaleDictionary, LocalesType } from '../../../models/locales';

export const MOVIE_DATE_MOMENT_FORMAT = 'YYYY-MM-DD';

export enum MovieTypes {
	movie = 1,
	series = 2,
	documentary = 3,
	standup = 4,
}

export interface MovieType {
	value: MovieTypes,
	label: string,
}

export type MovieTypeDictionary = {
	[key in MovieTypes]: MovieType
}

export const defaultMovieType: MovieType = {
	value: MovieTypes.movie,
	label: '',
};

export const getMovieType = (source: any, movieTypeDictionary: MovieTypeDictionary): MovieType => {
	//@ts-ignore
	const found = movieTypeDictionary[source];
	if (!found) {
		console.warn(`No movie type found for "${source}"`, source, movieTypeDictionary);
	}
	return found || defaultMovieType;
}

export interface Movie extends SuportedObject {
	id: string;
	createdDate: string;
	displayedCreatedDate: string;
	imdbURL: string;
	language: LocalesType;
	displayedLanguage: string;
	originalTitle: string;
	type: MovieTypes;
	typeName: string;
	posterURL: string;
	tags: Tag['id'][];
	displayedTags: string;
};

export interface MovieDictionary {
	[key: Movie['id']]: Movie
};

export const defaultMovie: Movie = {
	id: '',
	createdDate: '',
	displayedCreatedDate: '',
	imdbURL: '',
	language: LocalesType.ro,
	displayedLanguage: '',
	originalTitle: '',
	type: MovieTypes.movie,
	typeName: '',
	posterURL: '',
	tags: [],
	displayedTags: '',
};

export const getMovie = (source: any, movieDictionary: MovieDictionary): Movie => {
	//@ts-ignore
	const found = movieDictionary[source];
	if (!found) {
		console.warn(`No movie found for "${source}"`, source, movieDictionary);
	}
	return found || defaultMovie;
}

export const mapMovieForApi = (queryId: string, movie: Movie): any => {
	let payload: { [key: string]: any } = {
		imdbURL: movie.imdbURL,
		language: movie.language,
		originalTitle: movie.originalTitle,
		type: movie.type + '',
		posterURL: movie.posterURL,
		tags: movie.tags,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = movie.id;
	}
	return payload;
};

export const mapMovieFromAPI = (
	source: any,
	tagDictionary: TagDictionary,
	localeDictionary: LocaleDictionary,
	typeDictionary: MovieTypeDictionary,
): Movie => {
	const foundLocale = getLocale(source.language, localeDictionary);

	const displayedTags = (source.tags || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	const foundMovieType = getMovieType(source.type, typeDictionary);

	const displayedCreatedDate = moment(source.createdDate).format(DEFAULT_MOMENT_FORMAT);

	return {
		id: source.id,
		createdDate: source.createdDate,
		displayedCreatedDate: displayedCreatedDate,
		imdbURL: source.imdbURL,
		language: source.language,
		displayedLanguage: foundLocale.label,
		originalTitle: source.originalTitle,
		type: source.type,
		typeName: foundMovieType.label,
		posterURL: source.posterURL,
		tags: source.tags,
		displayedTags: displayedTags,
	}
};

export const mapMoviesFromAPI = (
	sources: any[],
	tagDictionary: TagDictionary,
	languageDictionary: LocaleDictionary,
	typeDictionary: MovieTypeDictionary,
): Movie[] => {
	return sources.map((source) => mapMovieFromAPI(source, tagDictionary, languageDictionary, typeDictionary));
};