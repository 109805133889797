import './IntervalSelector.css';
import React from 'react';
import { CustomDateField, CustomDateFieldProps } from '../CustomDateField';
import { MonthSelector } from '../MonthSelector/';
import { Moment } from 'moment';
import { getInputChangeParams } from '../../utils';
import moment from 'moment';

export interface IntervalSelectorProps {
	name: string;
	startDate: CustomDateFieldProps["value"];
	endDate: CustomDateFieldProps["value"];
	handleChange: (field: 'startDate' | 'endDate', value: string) => void;
	isLoading: CustomDateFieldProps["disabled"];
	labels: {
		startDate: string;
		endDate: string;
	};
	// TODO: Use MonthSelector onChange
	handleMonthCange?: Function;
	className?: string
}

export const IntervalSelector: React.FunctionComponent<IntervalSelectorProps> = ({ name, startDate, endDate, handleChange, isLoading, labels, handleMonthCange, className }) => {
	const handleMonthChangeLocal = ({ startDate, endDate }: { startDate: Moment, endDate: Moment }) => {
		if (handleMonthCange) {
			handleMonthCange(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
		}
	};
	const handleChangeLocal = (e: React.ChangeEvent) => {
		const [field, value] = getInputChangeParams<'startDate' | 'endDate', string>(e);
		if (field === 'startDate' || field === 'endDate') {
			handleChange(field, value);
		}
	};

	return (
		<div className={`interval-selector-root${' ' + className || ''}`}>
			<div>
				<CustomDateField
					id={`${name}-startDate`}
					name="startDate"
					label={labels.startDate}
					placeholder={labels.startDate}
					value={startDate}
					onChange={handleChangeLocal}
					disabled={isLoading}
					error={false}
				/>
			</div>
			<div>
				<CustomDateField
					id={`${name}-endDate`}
					name="endDate"
					label={labels.endDate}
					placeholder={labels.endDate}
					value={endDate}
					onChange={handleChangeLocal}
					disabled={isLoading}
					error={false}
				/>
			</div>
			{handleMonthCange && (
				<div>
					<MonthSelector disabled={isLoading || false} id="MontheSelector" numberOfMonths={12} startDate={moment(startDate, 'YYYY-MM-DD').toDate().toISOString()} handleChange={handleMonthChangeLocal} />
				</div>
			)}
		</div>
	);
};

export default IntervalSelector;