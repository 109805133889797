import React, { useContext } from 'react';
import { ICON_NAMES, CollapsableList, Divider, AdminDrawer } from '../../../../components';
import { BOOK_DEFINITIONS, MY_BOOKS_DEFINITIONS } from './itemDefinitions';
import { LabelsContext } from '../../../../context';
import { useHeaderItems } from '../../../../components/Header/itemDefinitions';
import { THEMES, getPreferredColorScheme } from '../../../../utils';

export const BookAppDrawer: React.FunctionComponent<{}> = () => {
	const { labels } = useContext(LabelsContext);
	const isDarkMode = getPreferredColorScheme() === THEMES.dark;

	const bookItems = useHeaderItems(BOOK_DEFINITIONS);
	const myBookItems = useHeaderItems(MY_BOOKS_DEFINITIONS);

	const sectionClassName = isDarkMode ? 'section-dark' : 'section';
	const sectionItemClassName = `section-item${isDarkMode ? ' section-item-dark' : ''}`;

	return (
		<AdminDrawer>
			<CollapsableList
				classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
				list={myBookItems}
				title={labels.myBooks}
				titleIcon={ICON_NAMES.book}
				isOpen
			/>
			<Divider />
			<CollapsableList
				classNames={{ anchor: sectionClassName, items: sectionItemClassName }}
				list={bookItems}
				title={labels.books}
				titleIcon={ICON_NAMES.localLibrary}
			/>
			<Divider />
		</AdminDrawer>
	);
};
