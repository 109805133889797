import { useState, useEffect } from 'react';

type ResponseType = {
	isMobile: boolean;
};

export const useIsMobileViewport = (): ResponseType => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const isMobile = windowWidth < 960;

	return { isMobile };
};