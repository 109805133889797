export enum App {
	none = '',
	admin = 'admin',
	books = 'books',
	finance = 'finance',
	movies = 'movies',
	progress = 'progress',
	recipes = 'recipes',
	health = 'health',
	minimalist = 'minimalist',
}