import React from 'react';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { RANDOM_COLORS } from '../../utils';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel: (props: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number }) => JSX.Element = ({
	cx, cy, midAngle, innerRadius, outerRadius, percent
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
}

interface ChartProps {
	type: string;
	data: {}[];
	dataKey: string;
	xField: string;
	lines: { title: string, field: string, color: string, name: string }[]
}

export const Chart: React.FunctionComponent<ChartProps> = ({ type, data, dataKey, xField, lines }) => {
	let body;
	if (type === 'pie') {
		body = (
			<PieChart>
				<Pie
					data={data}
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={80}
					fill="#8884d8"
					dataKey={dataKey}
				>
					{
						data.map((entry, index) => <Cell key={`cell-${index}`} fill={RANDOM_COLORS[index % RANDOM_COLORS.length]} />)
					}
				</Pie>
				<Legend verticalAlign="top" height={36} />
			</PieChart>
		);
	} else {
		body = (
			<LineChart
				data={data}
				margin={{
					top: 16,
					right: 16,
					bottom: 0,
					left: 24,
				}}
			>
				<Legend verticalAlign="top" height={36} />
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={xField} />
				<Tooltip />
				<Legend />
				<YAxis>
					{
						lines.map((l, i) => {
							return (
								<Label key={`label-${i}`} angle={270} position="left" style={{ textAnchor: 'middle' }}>
									{l.title}
								</Label>
							)
						})
					}
				</YAxis>
				{
					lines.map((l, i) => {
						return (
							<Line key={`line-${i}`} type="linear" dataKey={l.field} stroke={l.color} activeDot={{ r: 3 }} name={l.name} isAnimationActive={false} />
						)
					})
				}
			</LineChart>
		);
	}
	return (
		<>
			<ResponsiveContainer>
				{body}
			</ResponsiveContainer>
		</>
	);
}

export default Chart;