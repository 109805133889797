import { createContext } from 'react';
import { App } from '../AppEnum';

interface AppContextProps {
	app: App;
	handleExit: () => void;
	selectApp: (app: App) => void;
}

export const AppContext = createContext<AppContextProps>({
	app: App.none,
	handleExit: () => { },
	selectApp: () => { },
});