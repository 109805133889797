import { useCallback, useContext, useEffect, useState } from 'react';
import { getItem, postItem, patchItem } from '../API';
import { TYPES_ENUM } from '../API/TYPES';
import { Account, AccountingRecord, Asset, defaultAccount, defaultAccountingRecord, defaultAsset, defaultTransaction, Transaction } from '../Apps/FinanceApp/models';
import { defaultMovie, defaultMovieScreening } from '../Apps/MoviesApp/models';
import { defaultIngredient, defaultRecipe, Recipe } from '../Apps/ReceipesApp/models';
import { DEFAULT_NEW_QUERY_ID } from '../constants';
import { ErrorContext, UserContext } from '../context';
import { Book, Contact, Day, defaultBook, defaultContact, defaultDay, defaultSuggestedActivity, SuggestedActivity, User } from '../models';
import { defaultLabel, Label } from '../models/label';
import { defaultTimelineDot } from '../Apps/ProgressApp/models/timelineDot';
import { MappingFunction } from './useAPIList';
import { IStorage } from '../API/IStorage';
import { Author, OwnedBook, defaultAuthor, defaultOwnedBook } from '../Apps/BooksApp/models';

export type ValueOf<T> = T[keyof T];

type ResponseTuple<T> = [
	any,
	boolean,
	boolean,
	(queryId: string) => Promise<void>,
	(object: T) => void,
	(queryId: string, object: any) => Promise<void>,
	(field: keyof T, object: any) => void,
];

export const useApiObject = <T>(
	type: TYPES_ENUM,
	queryId: string,
	disableAutoLoading?: boolean,
	mappingFunction?: MappingFunction<T>,
	_storage?: IStorage<T>,
	// Add mapping function here
): ResponseTuple<T> => {
	// TODO: Split selected to other hook
	const { user } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [object, setObject] = useState<T>(getDefaultObject(type, user) as T);
	const { updateError } = useContext(ErrorContext);

	const load = useCallback(async (queryId: string) => {
		if (queryId !== DEFAULT_NEW_QUERY_ID) {
			setIsLoading(true);
			try {
				const result = await getItem<T>(type)(queryId);
				const mapped = mappingFunction ? mappingFunction(result) : result;
				setObject(mapped);
			} catch (error) {
				updateError(error as Error);
			} finally {
				setIsLoading(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateError, type]);

	const save = useCallback(
		async (queryId: string, object: any) => {
			setIsSaving(true);
			try {
				if (queryId === DEFAULT_NEW_QUERY_ID) {
					await postItem(type)(object);
				} else {
					await patchItem(type)(queryId, object);
				}
			} catch (error) {
				updateError(error as Error);
			} finally {
				setIsSaving(false);
			}
		},
		[updateError, type]
	);

	useEffect(() => {
		if (!disableAutoLoading) {
			load(queryId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [load, queryId]);

	const updateObject = (field: keyof T, value: ValueOf<T>) => {
		const updated: T = { ...object };
		updated[field] = value;
		setObject(updated);
	};

	return [
		object,
		isLoading,
		isSaving,
		load,
		setObject,
		save,
		updateObject,
	];
};

const getDefaultObject = (type: TYPES_ENUM, user: User) => {
	switch (type) {
		case TYPES_ENUM.ACCOUNTING_RECORD: return { ...defaultAccountingRecord } as AccountingRecord;
		case TYPES_ENUM.ACCOUNT: return { ...defaultAccount } as Account;
		case TYPES_ENUM.ASSET: return { ...defaultAsset } as Asset;
		case TYPES_ENUM.AUTHOR: return { ...defaultAuthor } as Author;
		case TYPES_ENUM.BOOK: return { ...defaultBook } as Book;
		case TYPES_ENUM.ACTIVITY_SUGGESTION: return { ...defaultSuggestedActivity } as SuggestedActivity;
		case TYPES_ENUM.CONTACT: return { ...defaultContact } as Contact;
		case TYPES_ENUM.DAY: return { ...defaultDay } as Day;
		case TYPES_ENUM.LABEL: return { ...defaultLabel } as Label;
		case TYPES_ENUM.OWNED_BOOK: return { ...defaultOwnedBook } as OwnedBook;
		case TYPES_ENUM.TRANSACTION: return { ...defaultTransaction, ...{ createdBy: user.id } } as Transaction;
		case TYPES_ENUM.INGREDIENT: return { ...defaultIngredient };
		case TYPES_ENUM.RECIPE: return { ...defaultRecipe } as Recipe;
		case TYPES_ENUM.TIMELINE_DOT: return { ...defaultTimelineDot, ...{ createdBy: user.id, createdByEmail: user.email } };
		case TYPES_ENUM.MOVIE: return { ...defaultMovie };
		case TYPES_ENUM.MOVIESCREENING: return { ...defaultMovieScreening, ...{ createdBy: user.id, createdByEmail: user.email } };
		default: return {};
	}
};