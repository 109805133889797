import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

interface KeyboardSnifferProps {
	disabled?: boolean
	handlers: {
		ADD?: () => void
		SAVE?: () => void
	}
}

export const SUPPORTED_EVENTS = Object.freeze({
	add: 'ADD',
	save: 'SAVE',
});

export const KeyboardSniffer: React.FunctionComponent<KeyboardSnifferProps> = ({ disabled, handlers }) => {
	// TODO: Remove ts-ignores
	const handleEventListner = useCallback((e) => {
		if (disabled) return;
		const detectedEvent = getHotkeyCombination(e);
		if (detectedEvent) {
			const detectedHandler = handlers[detectedEvent];
			if (detectedHandler) {
				e.preventDefault();
				detectedHandler();
			}
		}
	}, [disabled, handlers]);

	useEffect(() => {
		window.addEventListener('keydown', handleEventListner);
		return () => {
			window.removeEventListener('keydown', handleEventListner);
		}
	}, [handleEventListner]);

	return (
		<span />
	);
}

export default KeyboardSniffer;

/**
 * Returns a string for a hotKey combination
*/
const getHotkeyCombination = (event: React.KeyboardEvent) => {
	if (event.keyCode === 65 && event.ctrlKey) return SUPPORTED_EVENTS.add;
	if (event.keyCode === 83 && event.ctrlKey) return SUPPORTED_EVENTS.save;
	return '';
};
