import { LabelsGraph } from "../hooks/useLabels";

export const TRANSACTION_BUNDLE_TYPES = Object.freeze({
	IN: 'IN',
	OUT: 'OUT',
	BALANCE: 'BALANCE',
});

export const getTransactionBundleOptions = (labels: LabelsGraph) => {
	return [
		{
			label: labels.inLabel,
			value: 'IN',
		},
		{
			label: labels.outLabel,
			value: 'OUT',
		},
		{
			label: labels.balanceLabel,
			value: 'BALANCE',
		},
	];
}