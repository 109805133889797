import { AxiosResponse } from 'axios';

export interface DefaultListResponse {
	data: any[]
}

export interface DefaultObjectResponse {
	data: any
}

type expectedResponses = DefaultListResponse | DefaultObjectResponse;

export const parseDefaultResult = <T extends expectedResponses>(response: AxiosResponse<T>) => {
	return response.data.data
};