import { DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { ObjectFieldValidator } from '../../../validators';

export interface Author extends SuportedObject {
	id: string;
	fullName: string,
};

export interface AuthorDictionary {
	[key: Author["id"]]: Author;
};

export const defaultAuthor: Author = {
	id: '',
	fullName: '',
};

export const mapAuthorForApi = (queryId: string, author: Author): any => {
	let payload: { [key: string]: any } = {
		fullName: author.fullName,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = author.id;
	}
	return payload;
};

export const mapAuthorFromAPI = (
	source: any,
): Author => {
	return {
		id: source.id,
		fullName: source.fullName,
	};
};

export const mapAuthorsFromAPI = (
	sourcesFromAPI: any[],
): Author[] => {
	return sourcesFromAPI.map((source) => mapAuthorFromAPI(source));
};

export const validateAuthorField: ObjectFieldValidator<Author> = (fieldName, value) => {
	if (fieldName === 'fullName' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	return {
		isValid: true,
		messageId: '',
	}
}