import { Grid, GridProps as GridPropsBase } from '@mui/material';

export interface GridProps {
	item?: GridPropsBase['item'];
	xs?: GridPropsBase['xs'];
	className?: GridPropsBase['className'];
	style?: GridPropsBase['style'];
	direction?: GridPropsBase['direction'];
	container?: GridPropsBase['container'];
	spacing?: GridPropsBase['spacing'];
	sm?: GridPropsBase['sm'];
	md?: GridPropsBase['md'];
	lg?: GridPropsBase['lg'];
};
export { Grid };
export default Grid;