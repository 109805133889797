import { Tooltip } from "@mui/material";

export const withTooltip = (Component: JSX.Element, text: string) => {
	if (text) {
		return (
			<Tooltip title={text}>
				{Component}
			</Tooltip>
		);
	}
	return Component;
};