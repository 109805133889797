import { cloneDeep } from 'lodash';
import moment from 'moment';
import { DEFAULT_MOMENT_FORMAT } from '../../../constants';
import { getPercentage } from '../../../utils';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { Objective } from './objective';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';

/**
 * Returns default UI object
 * @deprecated
*/
export const getDefaultObjectivePlan = () => {
	return {
		id: '',
		title: '',
		description: '',
		dueDate: null,
		objectives: [],
		objectiveCount: 0,
		multiplier: 1,
		score: 0,
		maxScore: 0,
		percentageComplete: '0%',
		tags: [],
		displayedTags: '',
	};
};

/**
 * Turns an object from the API intro an object for the UI
 * @deprecated
*/
export const formatFromAPIObjectivePlan = (o: any) => {
	o.dueDate = o.dueDate ? moment(o.dueDate).format(DEFAULT_MOMENT_FORMAT) : '';
	o.percentageComplete = getPercentage(o.score / o.maxScore);
	o.objectiveCount = o.objectives.length;
	return cloneDeep(o);
};

/**
 * @deprecated
*/
export const formatToAPIObjectivePlan = (o: any) => {
	o.dueDate = o.dueDate ? moment(o.dueDate, DEFAULT_MOMENT_FORMAT).toISOString() : null;
	delete o.displayedTags;
	delete o.percentageComplete;
	delete o.objectiveCount;
	return cloneDeep(o);
};

export interface ObjectivePlan extends SuportedObject {
	id: string,
	title: string,
	description: string,
	dueDate: string,
	displayedDueDate: string,
	objectives: Objective['id'][],
	objectiveCount: number,
	percentageComplete: string,
	multiplier: number,
	score: number,
	maxScore: number,
	tags: Tag['id'][],
	displayedTags: string,
}

export interface ObjectivePlanDictionary {
	[key: ObjectivePlan['id']]: ObjectivePlan
};

export const defaultObjectivePlan: ObjectivePlan = {
	id: '',
	title: '',
	description: '',
	dueDate: '',
	displayedDueDate: '',
	objectives: [],
	objectiveCount: 0,
	multiplier: 1,
	score: 0,
	maxScore: 0,
	percentageComplete: '0%',
	tags: [],
	displayedTags: '',
};

export const getObjectivePlan = (source: any, objectivePlanDictionary: ObjectivePlanDictionary): ObjectivePlan => {
	//@ts-ignore
	const found = objectivePlanDictionary[source];
	if (!found) {
		console.warn(`No objective plan found for "${source}"`, source, objectivePlanDictionary);
	}
	return found || defaultObjectivePlan;
}

export const mapObjectivePlanForApi = (_queryId: string, objectivePlan: ObjectivePlan): any => {
	let payload: { [key: string]: any } = {
		id: objectivePlan.id,
		title: objectivePlan.title,
		description: objectivePlan.description,
		dueDate: objectivePlan.dueDate,
		objectives: objectivePlan.objectives,
		multiplier: objectivePlan.multiplier,
		score: objectivePlan.score,
		maxScore: objectivePlan.maxScore,
		tags: objectivePlan.tags,
	};
	return payload;
};

export const mapObjectivePlanFromAPI = (
	source: any,
	tagDictionary: TagDictionary,
): ObjectivePlan => {
	const displayedTags = (source.tags || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	const percentageComplete = getPercentage(source.score / source.maxScore);

	return {
		description: source.description,
		displayedTags: displayedTags,
		dueDate: source.dueDate,
		displayedDueDate: moment(source.dueDate).format(DEFAULT_MOMENT_FORMAT),
		id: source.id,
		maxScore: source.maxScore,
		multiplier: source.multiplier,
		objectives: source.objectives,
		objectiveCount: (source.objectives || []).length,
		percentageComplete: percentageComplete,
		score: source.score,
		tags: source.tags,
		title: source.title
	};
};


export const mapObjectivePlansFromAPI = (
	sources: any[],
	tagDictionary: TagDictionary,
): ObjectivePlan[] => {
	return sources.map((source) => mapObjectivePlanFromAPI(source, tagDictionary));
};