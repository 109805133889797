import { DEFAULT_NEW_QUERY_ID } from "../constants";
import { SuportedObject } from "../utils/filterListByFilters";
import { Skill } from "./skill";

export interface Story extends SuportedObject {
	id: string;
	startDate: string;
	endDate: string;
	jobTitle: string;
	employer: string;
	locale: string;
	skills: string;
	skillRatios: {
		id: Skill["id"];
		percentage: number;
	}[];
	paras: string[];
	markdown: string;
	summary: string;
	logo: string;
	logoAlt: string;
	createdDate: string;
	modifiedDate: string;
}

export interface StoryDictionary {
	[key: Story['id']]: Story
};

export const defaultStory = {
	id: '',
	startDate: '',
	endDate: '',
	jobTitle: '',
	employer: '',
	locale: '',
	skills: '',
	skillRatios: [],
	paras: [],
	markdown: '',
	summary: '',
	logo: '',
	logoAlt: '',
	createdDate: '',
	modifiedDate: '',
};

export const mapStoryForApi = (queryId: string, story: Story): any => {
	let payload: { [key: string]: any } = {
		startDate: story.startDate,
		endDate: story.endDate,
		jobTitle: story.jobTitle,
		employer: story.employer,
		locale: story.locale,
		skills: story.skills,
		skillRatios: story.skillRatios,
		paras: story.paras,
		markdown: story.markdown,
		summary: story.summary,
		logo: story.logo,
		logoAlt: story.logoAlt,
		createdDate: story.createdDate,
		modifiedDate: story.modifiedDate,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = story.id
	}
	return payload;
};

export const mapStoryFromAPI = (storyFromAPI: any) => {
	return {
		id: storyFromAPI.id,
		startDate: storyFromAPI.startDate,
		endDate: storyFromAPI.endDate,
		jobTitle: storyFromAPI.jobTitle,
		employer: storyFromAPI.employer,
		locale: storyFromAPI.locale,
		skills: storyFromAPI.skills,
		skillRatios: storyFromAPI.skillRatios,
		paras: storyFromAPI.paras,
		markdown: storyFromAPI.markdown,
		summary: storyFromAPI.summary,
		logo: storyFromAPI.logo,
		logoAlt: storyFromAPI.logoAlt,
		createdDate: storyFromAPI.createdDate,
		modifiedDate: storyFromAPI.modifiedDate,
	} as Story;
}

export const mapStoriesFromAPI = (storiesFromAPI: any[]): Story[] => {
	return storiesFromAPI.map((storyFromAPI) => mapStoryFromAPI(storyFromAPI));
};