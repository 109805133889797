import { useContext, useMemo } from 'react';
import { useAPIList } from './useAPIList';
import { mapObjectivesFromAPI, Objective, ObjectiveDictionary } from '../models/';
import { TYPES_ENUM } from '../API/TYPES';
import { TagsContext } from '../context';
import { turnListIntoDictionary } from '../utils';

type ResponseType = {
	objectiveDictionary: ObjectiveDictionary;
	objectives: Objective[];
	isLoadingObjectives: boolean;
	isDeletingObjective: boolean;
	selectedObjectiveIds: Objective['id'][];
	loadObjectives: (params: any) => Promise<void>;
	selectObjectives: (newIds: Objective[]) => void;
	selectAllObjectives: () => void;
	deleteObjective: (id: Objective['id']) => Promise<void>;
}

export const useObjectives = (initialStartDate?: string, initialEndDate?: string): ResponseType => {
	const { tagDictionary } = useContext(TagsContext);

	const [
		isLoadingObjectives,
		isDeletingObjective,
		raw,
		selectedObjectiveIds,
		loadObjectives,
		selectObjectives,
		selectAllObjectives,
		deleteObjective
	] = useAPIList<Objective>(TYPES_ENUM.OBJECTIVE, 'id', { startDate: initialStartDate, endDate: initialEndDate });
	const objectives = useMemo(
		() => mapObjectivesFromAPI(raw, tagDictionary),
		[raw, tagDictionary]
	);
	const objectiveDictionary = turnListIntoDictionary(objectives);

	return {
		objectiveDictionary,
		objectives,
		isLoadingObjectives,
		isDeletingObjective,
		selectedObjectiveIds,
		loadObjectives,
		selectObjectives,
		selectAllObjectives,
		deleteObjective,
	};
};