import React, { useContext } from 'react';
import { CustomSelector } from '../CustomSelector/';
import { mapListToSelectorOptions } from '../../reducers/selectors';
import { TagsContext } from '../../context';
import { Tag } from '../../models';

interface TagsFilterProps {
	className?: string;
	disabled?: boolean;
	selectedTags: Tag['id'][];
	selectTags: (newTags: Tag['id'][]) => void;
}

export const TagsFilter: React.FunctionComponent<TagsFilterProps> = ({ className, disabled, selectedTags, selectTags }) => {
	const contextValues = useContext(TagsContext);
	const { tags } = contextValues;
	const options = mapListToSelectorOptions(tags, 'id', 'text')

	const onChange = (field: string, value: Tag['id'][]) => {
		selectTags(value);
	};

	return (
		<CustomSelector
			id="Tags"
			name="tags"
			className={className}
			label={'Filter by tags'}
			value={selectedTags}
			options={options}
			handleChange={onChange}
			disabled={disabled}
			multiple
		/>
	);
}

export default TagsFilter;