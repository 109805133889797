export interface SuportedObject {
	[key: string]: any
}

export interface Filter {
	value: string | number
	field: string
}

/**
 * Filters the list based on filters
*/
export const filterListByFilters = <T extends SuportedObject>(rows: T[], filters: Filter[]) => {
	return rows.filter((row) => {
		const isEvaluated = evaluateFilters(row, filters);
		return isEvaluated;
	});
};

/**
 * Evaluates this row with the filters
*/
const evaluateFilters = <T extends SuportedObject>(row: T, filters: Filter[]): boolean => {
	// TODO: Include reunion filters
	let isRowEvaluated = false;
	let foundMatchingFilters: Filter[] = [];
	if (filters.length === 0) return !!row;
	for (let i = 0; i < filters.length; i++) {
		const filter = filters[i];
		if (evaluateFilter(row, filter)) {
			foundMatchingFilters.push(filter);
		}
		isRowEvaluated = foundMatchingFilters.length === filters.length;
	};
	return isRowEvaluated;
};

/**
 * Evaluates this row with the filters
*/
const evaluateFilter = <T extends SuportedObject>(row: T, filter: Filter) => {
	if (typeof filter.value === 'string') {
		if (row[filter.field]) {
			if (row[filter.field] === filter.value) {
				return row[filter.field] === filter.value;
			}
			return false;
		}
		console.warn(`Row has no "${filter.field}" value`);
	}
	return false;
};