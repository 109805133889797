import moment from "moment";
import { DEFAULT_NEW_QUERY_ID } from "../constants";
import { SuportedObject } from "../utils/filterListByFilters";
import { defaultProjectStatus, ProjectStatusDictionary, ProjectStatusEnum } from "./projectStatus";
import { UserDictionary, defaultUser } from "./user";

export interface Project extends SuportedObject {
	id: string;
	status: ProjectStatusEnum;
	displayedStatus: string;
	title: string;
	locale: string;
	summary: string;
	details: string;
	git: string;
	url: string;
	techs: string[];
	createdDate: string;
	modifiedDate: string;
	createdBy: string;
	createdByEmail: string;
	version: number;
};

export interface ProjectDictionary {
	[key: Project['id']]: Project
};

export const defaultProject: Project = {
	id: '',
	status: ProjectStatusEnum.REQ,
	displayedStatus: '',
	title: '',
	locale: '',
	summary: '',
	details: '',
	git: '',
	url: '',
	techs: [],
	createdDate: '',
	modifiedDate: '',
	createdBy: '',
	createdByEmail: '',
	version: 0,
};

export const mapProjectForApi = (queryId: string, project: Project): any => {
	let payload: { [key: string]: any } = {
		status: project.status,
		title: project.title,
		locale: project.locale,
		summary: project.summary,
		details: project.details,
		git: project.git,
		url: project.url,
		techs: project.techs,
		createdDate: project.createdDate,
		modifiedDate: project.modifiedDate,
		createdBy: project.createdBy,
		createdByEmail: project.createdByEmail,
		version: project.version,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = project.id
	}
	return payload;
};

export const mapProjectFromAPI = (
	source: any,
	userDictionary: UserDictionary,
	projectStatusDictionary: ProjectStatusDictionary
): Project => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser };
	const foundProjectStatus = projectStatusDictionary[source.status] || { ...defaultProjectStatus };
	return {
		id: source.id,
		status: source.status,
		displayedStatus: foundProjectStatus.label,
		title: source.title,
		locale: source.locale,
		summary: source.summary,
		details: source.details,
		git: source.git,
		url: source.url,
		techs: source.techs,
		createdDate: source.createdDate,
		modifiedDate: source.modifiedDate,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		version: source.version,
	};
};

export const mapProjectsFromAPI = (
	projectsFromAPI: any[],
	userDictionary: UserDictionary,
	projectStatusDictionary: ProjectStatusDictionary): Project[] => {
	return projectsFromAPI.map((projectFromAPI) => mapProjectFromAPI(projectFromAPI, userDictionary, projectStatusDictionary));
};

export const orderProjects = (projects: Project[], isAscending: boolean): Project[] => {
	return projects.sort((a, b) => {
		const isAfter = moment(a.modifiedDate, 'YYYY-MM-DD').isAfter(moment(b.modifiedDate, 'YYYY-MM-DD'));
		if (isAscending) {
			return isAfter ? 1 : -1;
		} else {
			return isAfter ? -1 : 1;
		}
	});
};